<template>
    <section class="clients-wrapp">
        <template v-if="showModal">
            <app-previewmodal :id="client_id" :step="step" @previewModal="previewModal"> </app-previewmodal>
        </template>
        <div class="container w-75">
            <div class="row">
                <div class="col-sm-12 col-md-8">
                    <p v-if="items.length == 0">No Any Candidates Yet.</p>
                    <div v-if="showClients" v-for="(client, index) in items" class="clients-details-block">
                        <client :data="client" :key="client.id" :type="role" :states="steps"
                          @preview="preview">
                        </client>
                        <a v-if="role == 'Admin' || role =='Super Admin'"
                          class="delete-icon"  @click.prevent="confirmDelete(client)" href="" >
                          <img src="/img/Close-Cancel.png" alt="close" class="close-icon-client">
                        </a>
                    </div>
                    <confirmation-modal
                      v-if="showConfirmModal"
                      :show="showConfirmModal"
                      @confirm='deleteClient'
                      @cancel="cancelDelete"
                      >
                      <span>Do you want to delete this applicant ?</span>
                    </confirmation-modal>
                </div>
                <div class="col-sm-12 col-md-4 order">
                    <div class="checklist-wrapp mb-5" v-if="showSteps">
                        <ul>
                            <li v-for="(step, index) in steps">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" :value="step.id" v-model="checkedStates"
                                        :id="step.id" @change="fetch($event)">
                                    <label class="custom-control-label" :for="step.id">{{ step.title }}</label>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="pagination-wrapp">
                <pagination :data="dataSet" @pagination-change-page="fetch"></pagination>
            </div>
        </div>
    </section>
</template>

<script>
    import VueMoment from 'vue-moment';
    Vue.use(VueMoment);

    import Client from './ClientComponent';
    import PreviewModal from './PreviewModalComponent';
    import ConfirmationModal from './ConfirmationModalComponent';
    export default {
        components: {
            'client': Client,
            'app-previewmodal': PreviewModal,
            'confirmation-modal': ConfirmationModal
        },
        data() {
            return {
                steps: [],
                checkedStates: [],
                dataSet: {},
                items: [],
                q: '',
                role: '',
                showModal: false,
                client_id: null,
                step: null,
                showSteps: false,
                showClients: false,
                page: 1,
                showConfirmModal: false,
                selectedClient: null,
            };
        },
        mounted(){
          
        },
        created() {
            this.fetchSteps();
            var url_string = window.location;
            var url = new URL(url_string);
            this.q = url.searchParams.get("q") || '';
            var steps = url.searchParams.get("steps") || [];
            if (steps.length > 0) {
                this.checkedStates = steps.split(',');
            }
            this.page = url.searchParams.get("page") || 1;
            this.fetch();
        },
        beforeDestroy() {
        },

        computed: {
            queryString() {
                return '/clients?q=' + this.q + '&steps=' + this.checkedStates + '&page=' + this.page;
            },

        },
        watch: {
        },
        methods: {

            fetch(page = this.page) {
                this.page = page;
                axios.get('/client/search?q=' + this.q + '&steps=' + this.checkedStates + '&page=' + this.page)
                    .then(response => {
                        this.showSteps = true;
                        this.dataSet = response.data.clients;
                        this.items = response.data.clients.data;
                        this.role = response.data.roles[0];
                        window.scrollTo(0, 0);
                    });

                this.updateUrl();
            },
            fetchSteps() {
                axios.get('/steps')
                    .then(response => {
                        this.showClients = true;
                        this.steps = response.data;
                        window.scrollTo(0, 0);
                    });
            },
            preview(data) {
                    this.showModal = true;
                    this.client_id = data.client_id;
                    this.step = data.step;
            },
            previewModal(val) {
                this.showModal = val;
            },
            updateUrl() {
                history.pushState(null, null, this.queryString);
            },
            confirmDelete(client) {
                this.selectedClient = client;
                this.showConfirmModal = true;
            },
            deleteClient(){
              axios.get('/clients/' + this.selectedClient.id + '/delete')
                  .then(response => {
                      this.showConfirmModal = false;
                      this.selectedClient   = null;
                      this.fetch(this.page);
                      flash(response.data.message);
                      window.scrollTo(0, 0);
                  }).catch(function(error) {
                      if (error.response.status === 403) {
                          flash(error.response.data.message,'danger')
                      }
                  });
            },
            cancelDelete(){
              this.showConfirmModal = false;
              this.selectedClient   = null;
            },

        },

    }

</script>
