const getters = {
    step(state){
        try{
            return state.step;
        }catch(err){
            console.log("Erro on step :"+err);
            return false;
        }
        
    },
    getClientID(state){
        try{
            return state.clientInfo.id;
        }catch(err){
            console.log("Error on getClientID :"+err);
            return false;
        }
        
    },
    getUser(state){
    	try{
    		return state.user;
    	}catch(err){
    		console.log("Error on getUser :"+err);
    		return false;
    	}
    },
    
    getPostLES(state){
    	try{
    		return state.postLES;
    	}catch(err){
    		console.log("Error on getPostLES :"+err);
    		return false;
    	}    	
    },
    getPostLESPostBy(state){
    	try{
    		return state.postLES.posted_by;
    	}catch(err){
    		console.log("Error on getPostBy :"+err);
    		return false;
    	}
    },
    
    getPostLESCourier(state){
    	try{
    		return state.postLES.courier_name;
    	}catch(err){
    		console.log("Error on getCourier :"+err);
    		return false;
    	}
    },

    getPostLESTrackID(state){
    	try{
    		return state.postLES.tracking_id;
    	}catch(err){
    		console.log("Error on getTrackID :"+err);
    		return false;
    	}
    },
    
    getLesApproved(state){
    	try{
    		return state.lesApproved;
    	}catch(err){
    		console.log("Error on getLesApproved :"+err);
    		return false;
    	}    	
    },

    getLesApprovedStatus(state){
    	try{
    		return state.lesApproved.status;
    	}catch(err){
    		console.log("Error on getLesApprovedStatus :"+err);
    		return false;
    	}
    },
    
    getCourseComp(state){
    	try{
    		return state.courseComp;
    	}catch(err){
    		console.log("Error on getCourseComp :"+err);
    		return false;
    	}
    	
    },

    getCourseCompStatus(state){
    	try{
    		return state.courseComp.status;
    	}catch(err){
    		console.log("Error on getCourseCompStatus :"+err);
    		return false;
    	}
    },

    // Get Inoice Obj
    getInvoice(state){
    	try{
    		return state.invoice;
    	}catch(err){
    		console.log("Error on getInvoice :"+err);
    		return false;
    	}
    	
    },
    
   // Client Info
    getClientInfo(state){
    	try{
    		return state.clientInfo;
    	}catch(err){
    		console.log("Error on getClientInfo :"+err);
    		return false;
    	}
    	
    },
    getAmount(state){
        try{
            return state.clientInfo.amount;
        }catch(err){
            console.log("Error on getAmount :"+err);
            return false;
        }
        
    },
    getTempClientInfo(state){
        try{
            return state.clientInfo;
        }catch(err){
            console.log("Error on getClientInfo Temp :"+err);
            return false;
        }
        
    },
    getFileDetail(state){
    	try{
    		return state.fileDetail;
    	}catch(err){
    		console.log("Error on getFileDetail :" + err);
    		return false;
    	}
    },
    getCurrentDoc(state){
    	try{
    		return state.currentDoc;
    	}catch(err){
    		console.log("Error on getFileDetail :" + err);
    		return false;
    	}

    },
    getMyFiles(state){
    	try{
          
    		return state[state.currentDoc].files;
    	}catch(err){
    		console.log("Error on getFileDetail :" + err);
    		return false;
    	}
    },
    getMyDocs(state){
        try{

            return state[state.currentDoc].files;
        }catch(err){
            console.log("Error on getMyDocs :" + err);
            return false;
        }
    },
    getShowFileUpload(state){
        try{

            return state.showFileUpload;
        }catch(err){
            console.log("Error on getShowFileUpload :" + err);
            return false;
        }
    },
    getDocValidity(state){
        try{
            return state[state.currentDoc].validity;
        }catch(err){
            console.log("Error on getDocStatus :" + err);
            return false;
        }
    },
    getCurrentIndex(state){
         try{
            return state.currentIndex;
        }catch(err){
            console.log("Error on getCurrentIndex :" + err);
            return false;
        }        
    },
    getEmailSend(state){
        try{
            return state.emailSend;
        }catch(err){
            console.log("Error on getEmailSend :" + err);
            return false;
        }   
    },
    getInvoiceEmailStatus(state){
        try{
            return state.invoice.email_status;
        }catch(err){
            console.log("Error on getEmailSend :" + err);
            return false;
        }  
    },
    getInvoiceEmailStatusTime(state){
        try{
            return state.invoice.email_status_time;
        }catch(err){
            console.log("Error on getEmailSend :" + err);
            return false;
        }  
    },
    getDratStatus(state){
        try{
            return state.clientInfo.draft_status;
        }catch(err){
            console.log("Error on getDratStatus :" + err);
            return false;
        }
    },
    getCompletionFlag(state){
        
        try{
            return state.clientInfo.completion_flag;
        }catch(err){
            console.log("Error on getCompletionFlag :" + err);
            return false;
        }
    }
    
    
	
	

}

export default (getters)
