<!--
  Ver : 2.0.3
  Developer : Hira Kumar Maharjan
  Modified Date : 28th Feb 2020
-->
<template>
  <section>
    <div class="container w-75">
      <div class="header-title-wrapp mb-5">
        <h5 class="header-title">CLIENT/CANDIDATE DETAILS</h5>
      
      </div>
      <template v-if="showFileSlider">
        <app-previewmodal
          :id="clientData.id"
          :step="step"
          @previewModal="closePreview"
          :selected="selectedIndex"
        ></app-previewmodal>
      </template>

      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="main-form-wrapp">
            <div class="form-group">
              <input
                type="text"
                :readonly="invalidUser"
                :class="inputClass"
                v-model="name"
                placeholder="Client/Candidate Name"
              >
            </div>
            <div class="form-group">
              <input
                type="text"
                :readonly="invalidUser"
                :class="[inputClass]"
                v-model="email"
                placeholder="Attendee Email Address"
              
              >
              <div class="row" v-if="showEmailMsg"> 
                <div class="col-sm-12 text-danger" v-html="emailMsg"></div>
              </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                :readonly="invalidUser"
                :class="[inputClass]"
                v-model="contact_no"
                class="form-control"
                placeholder="Attendee Contact Number"
                
              >
            </div>
            <div class="form-group">
              <div :class="['row',startEndDateClass]">
                <div class="form-wrapp col-sm-12 col-md-6">
                  <date-picker
                    id="custome-zindex"
                    :disabled="disabled"
                    @change="checkStartEndDate($event)"
                    v-model="start_date"
                    :editable="editable"
                    lang="en"
                    placeholder="Start Date (dd-mm-yyyy)"
                   format="DD-MM-YYYY"

                  ></date-picker>
                </div>
                <div class="form-wrapp col-sm-12 col-md-6">
                  <div :class="finishDateClass">
                    <date-picker
                      id="custome-zindex"
                      :disabled="disabled"
                      @change="checkStartEndDate($event)"
                      v-model="finish_date"
                      :editable="editable"
                      lang="en"
                      placeholder="End Date (dd-mm-yyyy)"
                      format="DD-MM-YYYY"
                    ></date-picker>
                  </div>
                </div>
              </div>
              <div class="row" v-if="showStartEndMsg">
                <div class="col-sm-12 text-danger" v-html="startEndErrorMsg"></div>
              </div>
            </div>

            <div class="form-group">
              <input
                :readonly="invalidUser"
                type="text"
                :class="inputClass"
                v-model="award_title"
                placeholder="Course Name(s)"
              >
            </div>
            <div class="form-group">
              <input
                type="text"
                :readonly="invalidUser"
                :class="[inputClass]"
                v-model="corresponding_les_office"
                placeholder="Corresponding LES Office"
              >
            </div>
            <div class="form-group">
              <input
                type="text"
                :readonly="invalidUser"
                :class="[inputClass]"
                v-model="les_mediator_email"
                placeholder="LES Mediator Email">
                          
              <div class="row" v-if="les_mediator_email_msg">
                <div class="col-sm-12 text-danger" v-html="show_les_mediator_email_msg"></div>
              </div>
            </div>
        
            <div class="form-group">
            
              <input
                type="text"
                :readonly="invalidUser"
                :class="inputClass"
                v-model="amount"
                placeholder="Amount in euro"
              >
            </div>
                <div class="form-group">
            
              <input
                type="text"
                :readonly="invalidUser"
                :class="inputClass"
                v-model="opportunity_link"
                placeholder="Opportunity link"
              >
            </div>
            <div class="form-group">
            
              <textarea
                type="text"
                :readonly="invalidUser"
                :class="inputClass"
                v-model="general_comments"
                placeholder="General Comments"
              ></textarea>
            </div>

            <div class="form-group">
              <div class="row">
              <div class="col-sm-6">
              <input type="checkbox" v-model="draft_status" />
                <label>Draft Mode?</label>
                
              </div>
               <div class="col-sm-6">
                 <input type="checkbox" v-model="completion_flag" /> <label>Course completed ?</label>
                </div>
            </div>
            </div>

           

            <div class="from-group mt-5">

              <a href="/clients" style="display:inline-block;">
              <img src="/img/Close-Cancel.png" alt="close" class="close-icon mr-4">
              </a>
              <button
                :readonly="invalidUser"
                type="submit"
                :class="['btn btn-transparent', checkValidation]"
                data-dismiss="modal"
                @click="submitForm($event)"
              >Save</button>
              
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-5 offset-md-1">
          <div class="file-uploder-wrapp">
            <ul>
              <li>
                <div :class="['media mb-5',postLESClass]">
                  <span aria-hidden="true" class="icon-cloud-upload d-flex mr-3"></span>
                  <div class="media-body">
                    <h5 class="mt-0 mb-0">Form 1 posted to LES</h5>
                     <a
                      href="#"
                      :class="['uploadDoc',uploadClass]"
                      data-toggle="modal"
                      data-target="#dragUpload"
                      @click="setCurrentDoc('postLES')"
                      data-backdrop="false"
                    >Upload document</a>
                    <ul class="list-inline">
                      <li class="list-inline-item" v-for="(file,index) in postLES.files">
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#fileDetail"
                          data-backdrop="false"
                          data-id="file.id"
                          @click="setFileDetail({id:file.id,doc:'postLES',step:1,index:index})"
                        >
                          <span class="file-icon">
                            <i class="fa fa-file-text-o" aria-hidden="true"></i>
                          </span>
                        </a>
                      </li>
                    </ul>
                   
                    <div class="email-status" v-if="emailSend.status">
                                         
                        <strong v-if="emailSend.send" class="text-success">
                          
                          Email sent at : <span :alt="emailSend.sentTime" :title="emailSendAt">{{emailSend.sentTime | moment("utc") | moment("from")}} </span>
                          
                        </strong>
                        <strong v-else class="text-danger">
                          Email not sent 
                        </strong>
                     
                      <!-- icon-refresh -->
                      <a href="#" title="Resend" @click.prevent="resendEmail" >
                        <span aria-hidden="true" :class="emailSendProgressClass"></span></a>
                    </div>
                    
                  </div>
                </div>
              </li>
              <li>
                <div :class="['media mb-5',lesApprovedClass]">
                  <span aria-hidden="true" class="icon-cloud-upload d-flex mr-3"></span>
                  <div class="media-body">
                    <h5 class="mt-0 mb-0">LES approved / Awaiting course completion</h5>
                  
                    <ul class="row">
                        <li class="col-6 mt-1 mb-1">
                            <div class="custom-control custom-checkbox">
                                <input type="radio" value="approved" v-model="lesApprovedStatus"
                                    class="custom-control-input" id="lesDoc1" @click="showLessApproveBtn" > 
                                <label class="custom-control-label" for="lesDoc1">Approved</label>
                            </div>
                        </li>
                         <li class="col-6 mt-1 mb-1">
                            <div class="custom-control custom-checkbox">
                                <input type="radio" value="rejected" v-model="lesApprovedStatus"
                                    class="custom-control-input" id="lesDoc2"  @click="showLessApproveBtn">
                                <label class="custom-control-label" for="lesDoc2">Rejected</label>
                            </div>
                        </li>
                        <li class="col-12">
                           <textarea class="comment-box" v-if="lesApprovedStatus=='rejected'" v-model="comment" placeholder="Enter Comment here ..." rows="3"  @click="showLessApproveBtn"></textarea>
                        </li>
                        <li class="col-12">
                          <button :class="['mt-1 btn btn-sm btnRound btn-transparent',lesApprovedBtnClass]" @click="sendLessApprovedData" > <span>Submit</span></button>
                        </li>
                        
                    </ul>
                  </div> <!-- media-body -->
                </div> <!-- lesApprovedClass -->
              </li>
              <li>
                <div :class="['media mb-5',courseCompDocClass]">
                  <span aria-hidden="true" class="icon-cloud-upload d-flex mr-3"></span>
                  <div class="media-body">
                    <h5 class="mt-0 mb-0">Form 2 Candidate Acknowledged / Ready To Invoice</h5>
                    <a
                      :class="['uploadDoc',uploadClass]"
                      href="#"
                      data-toggle="modal"
                      data-target="#dragUpload"
                      @click="setCurrentDoc('courseComp')"
                      data-backdrop="false"
                    >Upload document</a>

                    <ul class="list-inline">
                      <li class="list-inline-item" v-for="(file,index) in courseComp.files">
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#fileDetail"
                          data-backdrop="false"
                          data-id="file.id"
                          @click="setFileDetail({id:file.id,doc:'courseComp',step:3,index:index})"
                        >
                          <span class="file-icon">
                            <i class="fa fa-file-text-o" aria-hidden="true"></i>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div :class="['media mb-5',invoiceClass]">
                  <span aria-hidden="true" class="icon-cloud-upload d-flex mr-3"></span>
                  <div class="media-body">
                    <h5 class="mt-0 mb-0">Invoiced & Posted to LES</h5>

                    <a
                      v-if="user=='account'"
                      :class="['uploadDoc',uploadClass]"
                      href="#"
                      data-toggle="modal"
                      data-target="#dragUpload"
                      @click="setCurrentDoc('invoice')"
                      data-backdrop="false"
                    >Upload document</a>
                    <ul class="list-inline">
                      <li class="list-inline-item" v-for="(file,index) in invoice.files">
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#fileDetail"
                          data-backdrop="false"
                          data-id="file.id"
                          @click="setFileDetail({id:file.id,doc:'invoice',step:4,index:index})"
                        >
                          <span class="file-icon">
                            <i class="fa fa-file-text-o" aria-hidden="true"></i>
                          </span>
                        </a>
                      </li>
                    </ul>
                  
                    <template v-if="invoice.amount>0">&euro; {{invoice.amount}}</template>
                      <div class="invoice-email-status" v-if="invoice.validate">
                        <strong v-if="invoiceEmailStatus" class="text-success">
                          Email sent at : <span :alt="emailSend.sentTime" :title="emailSendAt">{{invoiceEmailStatusTime | moment("utc") | moment("from")}} </span>
                        </strong>
                        <strong v-else class="text-danger">
                          Email not sent 
                        </strong>
                  
                    </div>


                  </div>
                </div>
              </li>

              <li v-if="invoice.validate">
                  <i class="fa fa-check fa-1x text-success"></i> <strong class="text-success">Process Completed</strong>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
     
      <app-dragupload id="dragUpload" v-if="showDragUpload"></app-dragupload>
     
    </div>
  </section>
</template>
<script>
import DatePicker from "vue2-datepicker";
import adminStore from "../../store/modules/admin";
import DragUpload from "../global/DragUpload";
import FileDetail from "./FileDetail";
import PreviewModal from "../client/PreviewModalComponent";


export default {
  name: "admin",
  props: {
    client_detail: Object    
  },
  mounted() {

  },
  created() {
    this.$store.registerModule("admin", adminStore);
    window.store = this.$store;
    
    // console.log(JSON.stringify(this.client_detail));
    /* 1. Client Information*/
    try {
      if (this.client_detail != undefined) {
        if (this.client_detail.id != "") {

          /* 1. Fill data to Client Info */
          this.fillClientInfo();

          /* 2. Fill data to posted to LES */
          this.fillpostLES();

          /* 3. Fill LES Approved/Rejected */
          this.fillLesApproved();

          /* 4. Fill Course Completed Document Candidate Signed */
          this.fillCourseComp();

          /* 5. Invoice */
          this.fillInvoice();

          /* 6. Email Send Status */
          this.fillEmailSend();



        }
      }
    } catch (err) {
      console.log(" Error on created / client_detail " + err);
    }
  },
  data() {
    return {
      inputClass: "form-control",
      errorClass: "is-invalid",
      activeClass: "is-valid",
      startEndErrorMsg: "",
      editable: false,
      showStartEndMsg: false,
      startEndDateClass: "",
      selectedIndex: null,
      showModal: false,
      showFileSlider: false,
      advanceList: false,
      lesApprovedBtnStatus :false,
      validate: {
        name:false,
        email:false,
        contact_no:false,
        les_mediator_email:false,
        amount:false,
        /* Conditional Validation Variable */
        email_or_contact_no:false,
        start_finish_date :false
      },
      step: 0,     
      color:'blue',
       /* Email Format Validation */
      showEmailMsg :false,
      emailMsg : '',

      /* les_mediator_email */
      les_mediator_email_msg : false,
      show_les_mediator_email_msg : ''
    };
  },
  computed: {
    clientData: {
      get() {
        try {
          if (this.client_detail == undefined) {
            this.$store.commit(
              "admin/setTempClientInfo",
              JSON.parse(
                JSON.stringify(this.$store.getters["admin/getClientInfo"])
              )
            );

            return this.$store.getters["admin/getClientInfo"];
          } else {
            return this.client_detail;
          }
        } catch (err) {
          console.log("Error on clientData : " + err);
          return null;
        }
      },
      set(val) {
        return val;
      }
    },
    draft_status: {
      get() {
        try {
          
          return this.$store.getters["admin/getDratStatus"];
        } catch (err) {
          console.log("Error on draft_status : " + err);
        }
      },
      set(val) {
        this.$store.commit('admin/setDratStatus', val);
        return val;
      }
    },
    completion_flag: {
      get() {
        try {          
          return this.$store.getters["admin/getCompletionFlag"];
        } catch (err) {
          console.log("Error on completion_flag : " + err);
        }
      },
      set(val) {       
        this.$store.commit('admin/setCompletionFlag', val);
        return val;
      }
    },
    
    client: {
      get() {
        try {
          return this.$store.getters["admin/getClientInfo"];
        } catch (err) {
          console.log("Error on invalidUser : " + err);
        }
      },
      set(val) {
        //  this.$store.commit('admin/setClientInfo', this.client);
        return true;
      }
    },
    name: {
      get() {
        try {
          if(this.$store.getters["admin/getTempClientInfo"].name == '' || this.$store.getters["admin/getTempClientInfo"].name == undefined || this.$store.getters["admin/getTempClientInfo"].name == null){
            this.validate.name = false;
            return "";
          }else{
            this.validate.name = true;
            return this.$store.getters["admin/getTempClientInfo"].name;
          }
        } catch (err) {
          console.log("Error on invalidUser : " + err);
        }
      },
      set(val) {
        this.$store.commit("admin/name", val);
      }
    },
    email: {
      get() {
        try {
         
          let email = this.$store.getters["admin/getTempClientInfo"].email;
          if(email == null || email == undefined || email == ''){
            this.validate.email=false;
            return ""
          }else{
            if(this.validateEmail(email) == true){
              this.validate.email=true;
              this.showEmailMsg = false,
              this.emailMsg = "";
              return  email;
            }else{
              this.validate.email = false;
              this.showEmailMsg = true;
              this.emailMsg = "Email format is invalid";
              return  email;
            }
            
          }
        } catch (err) {
          console.log("Error on email : " + err);
          return "";
        }
      },
      set(val) {
        this.$store.commit("admin/email", val);
      }
    },
    contact_no: {
      get() {
        try {
         
          let contact_no=this.$store.getters["admin/getTempClientInfo"].contact_no;
          
          if(contact_no == null || contact_no == undefined || contact_no == ''){
            this.validate.contact_no = false;
            return "";
          }else{
            this.validate.contact_no = true;            
            return contact_no;
          }
         
        } catch (err) {
          console.log("Error on contact_no : " + err);
          return "";
        }
      },
      set(val) {
        this.$store.commit("admin/contact_no", val);
      }
    },

    start_date: {
      get() {
        try {
          return this.$store.getters["admin/getTempClientInfo"].start_date;
        } catch (err) {
          console.log("Error on start_date : " + err);
        }
      },
      set(val) {
       this.$store.commit("admin/start_date", val);
      }
    },
    finish_date: {
      get() {
        try {
          return this.$store.getters["admin/getTempClientInfo"].finish_date;
        } catch (err) {
          console.log("Error on finish_date : " + err);
        }
      },
      set(val) {
        this.$store.commit("admin/finish_date", val);
      }
    },
    checkStartDate:{
      get(){
        try{  
          console.log("Start Date :" + this.start_date);
          console.log("End Date :" + this.end_date);
        }catch(err){
          console.log("Error on checkStartDate" + err);
        }
      },
      set(val){
        return val;
      }
    },
    award_title: {
      get() {
        try {
          return this.$store.getters["admin/getTempClientInfo"].award_title;
        } catch (err) {
          console.log("Error on award_title : " + err);
        }
      },
      set(val) {
        this.$store.commit("admin/award_title", val);
      }
    },
    amount: {
      get() {
        try {
          
          let getAmount = this.$store.getters["admin/getAmount"];
          if(this.client.id==0){
            if(getAmount==''){
              this.validate.amount=false;
            }
          }
          return getAmount;

        } catch (err) {
          console.log("Error on amount : " + err);
        }
      },
      set(val) {
        
        
        // Validation for price pattern

        var patt = /^[0-9]+(\.[0-9]{1,2})?$/;
        // Only patter will be match if amount have any value
        if(val!=''){
          if(patt.test(val)){
            this.validate.amount=true;
            }else{
              this.validate.amount=false;
            }
        }
        this.$store.commit("admin/amount", val);
      }
    },
    opportunity_link:{
      get(){
        try{
          return this.client.opportunity_link;
        }catch(err){
          console.log("Error on opportunity_link :" + err);
        }
      },set(val){
        this.$store.commit('admin/opportunity_link',val);
      }
    },
    general_comments:{
      get(){
        try{
          return this.client.general_comments;
        }catch(err){
          console.log("Error on general_comments :" + err);
        }
      },set(val){
        this.$store.commit('admin/general_comments',val);
      }
    },
    corresponding_les_office: {
      get() {
        try {
          return this.$store.getters["admin/getTempClientInfo"].corresponding_les_office;
        } catch (err) {
          console.log("Error on corresponding_les_office : " + err);
        }
      },
      set(val) {
        this.$store.commit("admin/corresponding_les_office", val);
      }
    },
    les_mediator_email: {
      get() {
        try {
          let les_mediator_email = this.$store.getters["admin/getTempClientInfo"].les_mediator_email;
          if(les_mediator_email == null || les_mediator_email == undefined || les_mediator_email == ''){
            this.validate.les_mediator_email = false;
            return ''
          }else{
            
            if(this.validateEmail(les_mediator_email)==false){
              this.validate.les_mediator_email = false;
              this.les_mediator_email_msg = true;
              this.show_les_mediator_email_msg = 'Email format is invalid';
               return les_mediator_email;
            }else{
              this.validate.les_mediator_email = true;
               this.les_mediator_email_msg = false;
               this.show_les_mediator_email_msg = '';
              return les_mediator_email;
            }
            
          }
        } catch (err) {
          console.log("Error on les_mediator_email : " + err);
        }
      },
      set(val) {
        this.$store.commit("admin/les_mediator_email", val);
      }
    },
    invalidUser: {
      get() {
        try {
          if (this.user == "qc" || this.user == "account") {
            return true;
          } else {
            return false;
          }
        } catch (err) {
          console.log("Error on invalidUser : " + err);
        }
      }
    },   

    checkValidation: {
      get() {
        
        try {

          if(this.validate.name == true && this.validate.email == true && this.validate.les_mediator_email == true && this.validate.start_finish_date == true && this.validate.amount == true){
            return '';
          }else{
            return 'disabled';
          }
        
        } catch (err) {
          console.log("Error on checkValdiation :" + err);
          return "disabled";
        }
      },
      set(val) {
        return val;
      }
    },
    postLES: {
      get() {
        return this.$store.getters["admin/getPostLES"];
      },
      set(val) {
        return val;
      }
    },
    lesApproved: {
      get() {
        return this.$store.getters["admin/getLesApproved"];
      },
      set(val) {
        return val;
      }
    },
    courseComp: {
      get() {
        return this.$store.getters["admin/getCourseComp"];
      },
      set(val) {
        return val;
      }
    },

    invoice: {
      get() {
        try {
          return this.$store.getters["admin/getInvoice"];
        } catch (err) {
          console.log("Error on invoice get :" + err);
        }
      },
      set(val) {
        try {
          return val;
        } catch (err) {
          console.log("Error on invoice set :" + err);
        }
      }
    },
    finalLES: {
      get() {
        try {
          return this.$store.getters["admin/getFinalLES"];
        } catch (err) {
          console.log("Error on finalLES/get : " + err);
        }
      },
      set(val) {
        try {
          return val;
        } catch (err) {
          console.log("Error on finalLES/set : " + err);
        }
      }
    },
   
    finishDateClass: {
      get() {
        try {
          return false;
        } catch (err) {
          console.log("Error on finishDateClass: " + err);
        }
      },
      set(val) {
        return val;
      }
    },

    readOnlyClass: {
      get() {
        try {
          return this.invalidUser ? "" : "readonly";
        } catch (err) {
          console.log("Error on readOnlyClass : " + err);
        }
      },
      set(val) {
        return val;
      }
    },

    postLESClass: {
      get() {
       try {
          if (this.client.validate != true) {
            return "disabled";
          } else {
            return "";
          }
        } catch (err) {
          console.log("Error on postLESClass: " + err);
        }
      },
      set(val) {
        return val;
      }
    },

    lesApprovedClass: {
      get() {
        try {
          if ( this.postLES.validate == true && this.courseComp.files.length==0) {
            return "";
          } else {
            return "disabled";
          }
        } catch (err) {
          console.log("Error on lesApprovedClass" + err);
        }
      },
      set(val) {
        return val;
      }
    },
    lesApprovedBtnClass: {
      get() {
        try {
          if ( this.lesApprovedBtnStatus == true) {
            return "";
          } else {
            return "disabled";
          }
        } catch (err) {
          console.log("Error on lesApprovedClass" + err);
        }
      },
      set(val) {
        return val;
      }
    },
    courseCompDocClass: {
      get() {
        try {
          if (
            
            this.postLES.validate == true &&
            this.lesApproved.validate == true
          ) {
            return "";
          } else {
            return "disabled";
          }
        } catch (err) {
          console.log("Error on courseCompDocClass : " + err);
        }
      },
      set(val) {
        return val;
      }
    },
    showDragUpload:{
      get(){
        try{
          return this.$store.getters['admin/getShowFileUpload'];
        }catch(err){
          console.log("Error on showDragUpload get :" + showDragUpload);
        }
      },
      set(val){
        try{
          return val;
        }catch(err){
          console.log("Error on showDragUpload set :" + showDragUpload);
        }
      }
    },
    uploadClass: {
      get() {
        try {
          if (this.invalidUser) {
            return "disabled";
          } else {
            return "";
          }
        } catch (err) {
          console.log("Error on uploadClass : " + err);
        }
      }
    },

    invoiceClass: {
      get() {
        try {
          let courseComp = this.$store.getters["admin/getCourseComp"];
          if (
            
            this.postLES.validate == true &&
            this.lesApproved.validate == true &&
            this.courseComp.validate == true
          ) {
            if (this.client.user != "admin" && this.invoice.validate != true) {
              return "disabled";
            } else {
              return "";
            }
          } else {
            return "disabled";
          }
        } catch (err) {
          console.log("Erron on invoiceClass :" + err);
        }
      },
      set(val) {
        return val;
      }
    },

    finalLESClass: {
      get() {
        try {
          if (
            
            this.postLES.validate == true &&
            this.lesApproved.validate == true &&
            this.courseComp.validate == true &&
            this.invoice.validate == true
          ) {
            return "";
          } else {
            return "disabled";
          }
        } catch (err) {
          console.log("Erron on finalLESClass :" + err);
        }
      },
      set(val) {
        return val;
      }
    },

    user: {
      get() {
        try {
          return this.$store.getters["admin/getUser"];
        } catch (err) {
          console.log("Error on user/get :" + err);
        }
      },
      set(val) {
        try {
          return val;
        } catch (err) {
          console.log("Error on user/set :" + err);
        }
      }
    },

    disabled: {
      get() {
        try {
          if (this.invalidUser) {
            return true;
          } else {
            return false;
          }
        } catch (err) {
          console.log("Error on disabled :" + err);
        }
      },
      set(val) {
        try {
          return val;
        } catch (err) {
          console.log("Error on disabled :" + err);
        }
      }
    },
    lesApprovedStatus: {
      get() {
          try {
              return this.$store.getters['admin/getLesApprovedStatus'];
          } catch (err) {
              console.log("Error on lesApprovedStatus :" + err);
          }
      },
      set(val) {
          try {
              // Set Current Document = lesApproved
              this.$store.commit("admin/setCurrentDoc", "lesApproved");
              // Set lesApproved Status
              this.$store.commit('admin/setLesApprovedStatus', val);
              
                   
          } catch (err) {
              console.log("Error on lesApprovedStatus :" + err);
          }
      }
    },
    comment:{
      get(){
          try{
             return this.lesApproved.comment;
          } catch (err) {
              console.log("Error on lesApproved/comment get :" + err);
          }
      },
      set(val){
          try{
             
              this.$store.commit("admin/setCurrentDoc", "lesApproved");
              this.$store.commit('admin/setCommentOnLesApproved',val);
              
          } catch (err) {
              console.log("Error on lesApproved/comment set :" + err);
          }
      }
    },
    emailSend:{
      get(){
          try{
             return this.$store.getters['admin/getEmailSend'];
          } catch (err) {
              console.log("Error on emailSend get :" + err);
          }
      },
      set(val){
          try{
             return val;
          } catch (err) {
              console.log("Error on emailSend set :" + err);
          }
      }
    },
    emailSendAt:{
      get(){
        try{
          return new Date(this.emailSend.sentTime);
          //return this.emailSend.sentTime;
          let rawDateTime = this.emailSend.sentTime;
          let split1 = rawDateTime.split('+');
          let split2 = split1[0].split('T');
          let mytime = split2[0]+ " " + split2[1];
          return mytime;
        }catch(err){
          console.log("Error on emailSendAt :" + err);
        }
      }
    },
    emailSendProgressClass:{
      get(){
          try{
             if(this.emailSend.progress=="sending"){
                return "fa fa-circle-o-notch fa-spin  fa-fw";
             }else{
                return "icon-refresh";
             }
          } catch (err) {
              console.log("Error on emailSendProgressClass get :" + err);
          }
      },
      set(val){
          try{
             return val;
          } catch (err) {
              console.log("Error on emailSendProgressClass set :" + err);
          }
      }
    },
    invoiceEmailStatus:{
      get(){
        try{
          console.log(typeof this.$store.getters['admin/getInvoiceEmailStatus']);
          if(this.$store.getters['admin/getInvoiceEmailStatus'] == 1){
            return true;
          }else{
            return false;
          }
        }catch(err){
          console.log("Error on invoiceEmailStatus get :" + err);
        }
      },set(val){
        return val;
      }
    },
    invoiceEmailStatusTime:{
      get(){
        try{
          return this.$store.getters['admin/getInvoiceEmailStatusTime'];

        }catch(err){
          console.log("Error on invoiceEmailStatusTime get :" + err);
        }
      },set(val){
        return val;
      }
    },
  },
  methods: {
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    fillClientInfo: function() {
      try {
        /* 1. Client Information */
        var clientInfoValidate;
        if (this.client_detail.name != "") {
          clientInfoValidate = true;
        } else {
          clientInfoValidate = false;
        }
        // console.log(JSON.stringify(this.client_detail));
        var clientInfo = {
          id: this.client_detail.id,
          email: this.client_detail.email,
          award_title: this.client_detail.award_title,
          awarding_address: this.client_detail.awarding_address,
          awarding_telephone: this.client_detail.awarding_telephone,
          contact_no: this.client_detail.contact_no,
          finish_date: this.client_detail.finish_date,
          name: this.client_detail.name,
          start_date: this.client_detail.start_date,
          total_days: this.client_detail.total_days,
          corresponding_les_office: this.client_detail.corresponding_les_office,
          les_mediator_email: this.client_detail.les_mediator_email,
          amount: this.client_detail.amount,
          validate: clientInfoValidate,
          opportunity_link: this.client_detail.opportunity_link,
          general_comments : this.client_detail.general_comments,
          draft_status : this.client_detail.draft_status,
          completion_flag : this.client_detail.completion_flag

        };
        
        this.validate.start_finish_date=true;
        this.validate.amount=true;

        this.$store.commit("admin/setClientInfo", clientInfo);
      } catch (err) {
        console.log("Erro on methods/fillClientInfo :" + err);
      }
    },
    fillpostLES: function() {
      try {
        if (this.client_detail.posted_to_les != null) {
          // Validation Recognization of Post LES
          var postLESValidate;
          if (
            this.client_detail.posted_to_les.courier_name != "" &&
            this.client_detail.posted_to_les.tracking_id != "" &&
            this.client_detail.posted_to_les.documents.length > 0
          ) {
            postLESValidate = true;
          } else {
            postLESValidate = false;
          }

          var postLES = {
            id: this.client_detail.posted_to_les.id,
            files: this.client_detail.posted_to_les.documents,
            courier_name: this.client_detail.posted_to_les.courier_name,
            posted_by: this.client_detail.posted_to_les.posted_by,
            tracking_id: this.client_detail.posted_to_les.tracking_id,
            validate: postLESValidate
          };

          this.$store.commit("admin/setPostLES", postLES);
        }
      } catch (err) {
        console.log("Error on methods/fillpostLES" + err);
      }
    },
    fillLesApproved: function() {
      try {
        if (this.client_detail.les_status != null) {
          let lesStatusValidate;
          if (
            this.client_detail.les_status.status == "approved"
          ) {
            lesStatusValidate = true;
          } else {
            lesStatusValidate = false;
          }
          var lesApproved = {
            id: this.client_detail.les_status.id,
            status: this.client_detail.les_status.status,
            comment: this.client_detail.les_status.comment,
            validate: lesStatusValidate
          };

          this.$store.commit("admin/setLesApproved", lesApproved);
        }
      } catch (err) {
        console.log("Error on methods/fillLesApproved" + err);
      }
    },
    fillCourseComp: function() {
      try {
        if (this.client_detail.course_completed != null) {
          var courseCompValidate;
          if (this.client_detail.course_completed.documents.length > 0) {
            courseCompValidate = true;
          } else {
            courseCompValidate = false;
          }

          if (this.client_detail.course_completed != null) {
            var courseComp = {
              id: this.client_detail.course_completed.id,
              files: this.client_detail.course_completed.documents,
              validate: courseCompValidate
            };
          }

          this.$store.commit("admin/setCourseCompleted", courseComp);
        }
      } catch (err) {
        console.log("Error on methods/fillCourseComp");
      }
    },
    fillInvoice: function() {
      try {
        if (this.client_detail.invoice != null) {
          var invoiceValidate;
          if (
            this.client_detail.invoice.documents.length > 0 &&
            this.client_detail.invoice.status != ""
          ) {
            invoiceValidate = true;
          } else {
            invoiceValidate = false;
          }

          if (this.client_detail.invoice != null) {
            var invoice = {
              id: this.client_detail.invoice.id,
              files: this.client_detail.invoice.documents,
              status: this.client_detail.invoice.status,
              amount: this.client_detail.invoice.amount,
              email_status: this.client_detail.invoice.email_status,
              email_status_time: this.client_detail.invoice.email_status_time,
              validate: invoiceValidate,

            };
          }

          this.$store.commit("admin/setInvoice", invoice);
        }
      } catch (err) {
        console.log("Error on methods/fillInvoice");
      }
    },
    
    fillEmailSend:function(){
      try{
          var obj = {status:true}
          if(this.client_detail.posted_to_les !=null && this.client_detail.posted_to_les != undefined){
            if(this.client_detail.posted_to_les.email_status==0){
              obj.send=false;
            }else{
              obj.send=true;
            }
            obj.progress='finished';
            obj.sentTime=this.client_detail.posted_to_les.email_status_time;
            store.commit('admin/setEmailSend',obj);
          }
          

      }catch(err){
        console.log("Error on fileEmailSend :" + err);
      }
   
    },

    setCurrentDoc: function(doc) {
      try {
        this.$store.commit("admin/setCurrentDoc", doc);
        this.$store.commit("admin/setShowFileUpload", true);
    
      } catch (err) {
        console.log("Error on setCurrentDoc :" + err);
      }
    },

    checkStartEndDate: function(event) {
      try {
        if (this.start_date != null && this.finish_date != null) {
         
          var mystartDate = new Date(this.start_date);
          var myfinishDate = new Date(this.finish_date);
         
          // Set Zero for hour,minute,second,miliseciont
          mystartDate.setHours(0,0,0,0);
          myfinishDate.setHours(0,0,0,0);

          if (mystartDate != "Invalid Date" && myfinishDate != "Invalid Date") {
            if (mystartDate > myfinishDate) {
              console.log("mystartDate is greater end")
              this.validate.start_finish_date = false;
              this.startEndErrorMsg ="Start Date must be lower than End Date";
              this.showStartEndMsg = true;
              this.startEndDateClass = "is-invaid";              
            } else {
              console.log("matched")
              this.validate.start_finish_date = true;
              this.startEndErrorMsg = "";
              this.showStartEndMsg = false;
              this.startEndDateClass = "";
              
            }
          } else {
            
          }
        }
      } catch (error) {
        console.log("error on checkStartEndDate ", error);
      }
    },
    convertDate: function(val) {
      var yy = val.getUTCFullYear();
      var mm = "0" + parseInt(val.getMonth() + 1);
      mm = mm.slice(-2);
      var dd = "0" + parseInt(val.getDate());
      dd = dd.slice(-2);
      return yy + "-" + mm + "-" + dd + " " + "00:00:00";
    },
    setFileDetail: function(obj) {
      try {
        this.step = obj.step;
        this.selectedIndex = obj.index;
        if (this.step > 0 && this.clientData.id > 0) {
          this.showFileSlider = true;
        } else {
          this.showFileSlider = false;
        }
      } catch (err) {
        console.log("Error on setFileDetail : " + err);
      }
    },
    submitForm: function() {
      try {
        // Start Date
        if (
          typeof this.client.start_date == "object" &&
          this.client.start_date != null
        ) {
          this.$store.commit(
            "admin/start_date",
            this.convertDate(this.client.start_date)
          );
        } else {
          if (
            typeof this.client.start_date == "string" &&
            this.client.start_date.length < 1
          ) {
            this.$store.commit("admin/start_date", "");
          }
        }

        // Finish Date
        if (
          typeof this.client.finish_date == "object" &&
          this.client.finish_date != null
        ) {
          this.$store.commit(
            "admin/finish_date",
            this.convertDate(this.client.finish_date)
          );
        } else {
          if (
            typeof this.client.finish_date == "string" &&
            this.client.finish_date.length < 1
          ) {
            this.$store.commit("admin/finish_date", "");
          }
        }

        //console.log(JSON.stringify(this.client));
        this.$store.dispatch("admin/setClientInfo", this.client);
      } catch (error) {
        console.log("Error on submitForm ", error.message);
      }
    },
    closePreview: function(val) {
      try {
        console.log("closePreview" + val);
        this.showFileSlider = val;
      } catch (err) {
        console.log("Error on setCurrentDoc :" + err);
      }
    },
   
    sendLessApprovedData:function(){
      let obj = JSON.parse(JSON.stringify(this.lesApproved));
      obj.client_id=this.client.id;
      console.log(JSON.stringify(obj));
      this.$store.dispatch('admin/updateLesApproved',obj);
    },
    showLessApproveBtn:function(){
      this.lesApprovedBtnStatus=true;
    },
    customFormatter(date) {

     //return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },

    resendEmail:function(){
      
        this.$store.dispatch('admin/resendEmail',this.client.id);
      
      
    }

  },
  components: {
    DatePicker,
    "app-dragupload": DragUpload,
    "app-filedetail": FileDetail,
    "app-previewmodal": PreviewModal,
    
  }
};
</script>
<style lang="scss" scoped>


.mx-datepicker {
  width: 100%;
}

.mx-input {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  border-bottom: 1px solid #ced4da;
  box-shadow: none;
  padding-left: 0;
  color: #000000;
  font-family: "HelveticaNeue";
}

input.mx-input::-webkit-input-placeholder {
  font-family: "HelveticaNeue";
  color:#ccc;
  
}

input.mx-input::-moz-placeholder { 
  font-family: "HelveticaNeue";
  color: #ccc;
  opacity: 1;
}
input.mx-input::-ms-input-placeholder { 
  font-family: "HelveticaNeue";
  color: #ccc;
  opacity: 1;
} 
#custome-zindex .mx-datepicker-popup {  
  z-index: 1030;
}
.btn.btnRound.btn-sm{
  border-radius: 20px;
padding: 5px 15px;
}

</style>
