const state = {
    user:'admin',
    currentDoc:'postLES',
    currentIndex:null,
    showFileUpload:false,
    step:0,
    clientInfo:{
        id:'0',
        name:'',
        email:'',
        contact_no:'',
        start_date:'',
        finish_date:'',
        total_days:'',
        award_title:'',
        awarding_body_id:'',    
        awarding_telephone:'',
        awarding_address: '',
        amount:null,
        corresponding_les_office:'',
        les_mediator_email:'',
        opportunity_link:'',
        general_comments:'',
        draft_status:0,
        completion_flag:0,
        step_id:0,
        validate:false
    },
    validate:{
        startDate:false,
        finishDate:false,
        totalDays:false,

    },
    postLES:{
        id:0,
        files:[],
        validate:false,
    },
    lesApproved:{
        id:0,
        status:'',
        comment:'',
        validate:false,
    },
    courseComp:{
        id:0,
        files:[],
        validate:false,
    },
    invoice:{
        id:0,
        files:[],
        validate:false,

    },
    emailSend:{
        status:false,
        send:false,
        files:[],
        responseMsg:'',
        progress:'finished'
    },
    
    
    
}
export default (state)
