import Api       from '../../../Api/Admin/admin'
import ClientApi from '../../../Api/Admin/api'

const actions = {
    uploadFiles (state,data) {

        switch(data.doc) {
          case 'regDoc':
            if (data.id > 0) {
                ClientApi.updateRegistrationDoc(data);

            }else{
                ClientApi.saveRegistrationDoc(data);
            }
            break;
          case 'postLES':
            if (data.id > 0) {
                ClientApi.updatePostedToLES(data);

            }else{
                ClientApi.savePostedToLES(data);
            }
            break;
            case 'lesApproved':
            if (data.id > 0) {
                ClientApi.updateLesStatus(data);

            }else{
                ClientApi.saveLesStatus(data);
            }
            break;
            case 'courseComp':
            if (data.id > 0) {
                ClientApi.updateCourseCompleted(data);

            }else{
                ClientApi.saveCourseCompleted(data);
            }
            break;
            case 'invoice':
            if (data.id > 0) {
                ClientApi.updateInvoice(data);

            }else{
                ClientApi.saveInvoice(data);
            }
            break;
            case 'finalLES':
            if (data.id > 0) {
                ClientApi.updateFinalLES(data);

            }else{
                ClientApi.saveFinalLES(data);
            }
            break;
          default:
            // code block
        }
    	
    },
    updateLesApproved(state,data){
        if (data.id > 0) {
            ClientApi.updateLesStatus(data);

        }else{
            ClientApi.saveLesStatus(data);
        }
    },
    deleteFile(state,data){
        Api.deleteFile(data);
    },
    setClientInfo(state, data) {
        console.log("Seding Data");
        console.log(JSON.stringify(data));
        //return false;
        if (data.id > 0) {
            ClientApi.update(data);

        }else{
            ClientApi.save(data);
        }
    },
    addedFile(state,data){
        Api.addedFile(data);
    },
    saveDocInfo(state,data){
        console.log(data);
    },
    step(state,data){
        console.log(data);       
    },
    resendEmail(state,data){
        console.log("Action");
        ClientApi.resendEmail(data);
    }



}

export default (actions);
