import store from '../../store/store'
export default {
    save(data) {
        axios({
            url: '/clients/store',
            method: 'post',
            data: data
        }).then(function(response) {
            if (response.data.status == 'success') {
                store.commit('admin/setClientInfo',response.data.data);
                store.commit('admin/setClientInfoValidate',true);

                let split = window.location.href.split('clients');
                let newhref= split[0]+"clients/"+response.data.data.id+"/edit";
                window.location.replace(newhref);
                flash(response.data.message)
            }
        }).catch(function(error) {
            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });
    },
    update(data) {
        axios({
            url: '/clients/'+data.id+'/update',
            method: 'post',
            data: data
        }).then(function(response) {
            if (response.data.status == 'success') {
                store.commit('admin/setClientInfo',response.data.data);
                store.commit('admin/setClientInfoValidate',true);
                location.reload();
                flash(response.data.message)
                    }
           /*if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }*/
        }).catch(function(error) {
            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });
    },
    /*saveRegistrationDoc(data){
        axios({
            url: '/clients/registration/store',
            method: 'post',
            data: data
        }).then(function(response) {
             if (response.data.status == 'success') {
                store.commit('admin/setRegDoc',response.data.data);
                store.commit('admin/setRegDocValidate',true);
                 flash(response.data.message)

            }
        }).catch(function(error) {
            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });

    },
    updateRegistrationDoc(data){
        axios({
            url: '/clients/registration/'+data.id+'/update',
            method: 'post',
            data: data
        }).then(function(response) {
             if (response.data.status == 'success') {
                store.commit('admin/setRegDoc',response.data.data);
                store.commit('admin/setRegDocValidate',true);
                 flash(response.data.message)

            }
        }).catch(function(error) {
            if (error.response.status === 403) {

                flash(error.response.data.message,'danger')
            }

        });

    },
*/
    savePostedToLES(data){
        axios({
            url: '/clients/posted-to-les/store',
            method: 'post',
            data: data
        }).then(function(response) {
             if (response.data.status == 'success') {
                store.commit('admin/setPostLESPost',response.data.data);
               // store.commit('admin/setPostedToLESValidate',true);


                /* If Email is not send with files. Send proper message */
               var obj = {};
               if(response.data.data.email_status == 0){
                    obj = {
                        status:true,
                        send:false,
                        progress:'finished'
                    }

               }else{
                    obj = {
                        status:true,
                        send:true,
                        sentTime:response.data.data.email_status_time,
                        files:[{name:response.data.data.files[0].title}],
                        progress:'finished'
                    }
               }
               store.commit('admin/setEmailSend',obj);

               flash(response.data.message)
                 //enable les approved

            }
        }).catch(function(error) {
            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });

    },
    updatePostedToLES(data){
        axios({
            url: '/clients/posted-to-les/'+data.id+'/update',
            method: 'post',
            data: data
        }).then(function(response) {
             if (response.data.status == 'success') {
                store.commit('admin/setPostLESPost',response.data.data);
                // store.commit('admin/setPostedToLESValidate',true);

                  var obj = {};

               if(response.data.data.email_status == 0){
                    obj = {
                        status:true,
                        send:false,
                        responseMsg:'Email not sent',
                        progress:'finished',
                        files:[{name:response.data.data.files[0].title}]
                    }

               }else{
                    obj = {
                        status:true,
                        send:true,
                        responseMsg:'Email sent',
                        sentTime:response.data.data.email_status_time,
                        progress:'finished',
                        files:[{name:response.data.data.files[0].title}]
                    }
               }
               store.commit('admin/setEmailSend',obj);
                               flash(response.data.message)

            }
        }).catch(function(error) {

            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });

    },

    saveLesStatus(data){
        axios({
            url: '/clients/status/store',
            method: 'post',
            data: data
        }).then(function(response) {
             if (response.data.status == 'success') {
                store.commit('admin/setLesApproved',response.data.data);
                flash(response.data.message)

                let validate;
                if(response.data.data.status=="approved"){
                    validate=true;
                }else{
                    validate=false;
                }
                store.commit('admin/setLessApprovedValidate',validate);
                  //enable course completed


            }
        }).catch(function(error) {
            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });

    },
    updateLesStatus(data){
        axios({
            url: '/clients/status/'+data.id+'/update',
            method: 'post',
            data: data
        }).then(function(response) {
             if (response.data.status == 'success') {
                store.commit('admin/setLesApproved',response.data.data);
                 flash(response.data.message)
                 let validate;
                if(response.data.data.status=="approved"){
                    validate=true;
                }else{
                    validate=false;
                }
                store.commit('admin/setLessApprovedValidate',validate);

            }
        }).catch(function(error) {
            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });

    },
    resendEmail(data){
        console.log("API :"+data);
         var obj = {
                        status:true,
                        send:false,
                        progress:'sending',
                        sentTime:'',

                    }
                store.commit('admin/setEmailSend',obj);

        axios({
            url: '/clients/posted-to-les/'+data+'/resendEmail',
            method: 'post',
            data: data
        }).then(function(response) {

            if (response.data.status == 'success') {
                flash(response.data.message)

                var obj = {
                        status:true,
                        send:true,
                        progress:'finished',
                        sentTime:response.data.data.email_status_time,

                    }
                store.commit('admin/setEmailSend',obj);
                store.commit('admin/setPostedToLESValidate',true);
            }else{
                var obj = {
                    status:true,
                    send:false,
                    progress:'finished',
                    sentTime:response.data.data.email_status_time,
                }
                store.commit('admin/setEmailSend',obj);
                store.commit('admin/setPostedToLESValidate',false);
                flash(error.response.data.message,'danger')
            }

        }).catch(function(error) {
            console.log("Catch")
            if (error.response.status === 403) {
                console.log("403")
            }
            flash(error.response.data.message,'danger')
        });

    },

    saveCourseCompleted(data){
        axios({
            url: '/clients/course-completed/store',
            method: 'post',
            data: data
        }).then(function(response) {
             if (response.data.status == 'success') {
                store.commit('admin/setCourseCompleted',response.data.data);
                 store.commit('admin/setCourseCompValidate',true);
                  flash(response.data.message)

                //enable invoice

            }
        }).catch(function(error) {
            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });

    },
    updateCourseCompleted(data){
        axios({
            url: '/clients/course-completed/'+data.id+'/update',
            method: 'post',
            data: data
        }).then(function(response) {
             if (response.data.status == 'success') {
                store.commit('admin/setCourseCompleted',response.data.data);
                 store.commit('admin/setCourseCompValidate',true);
                  flash(response.data.message)

            }
        }).catch(function(error) {
            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });

    },
    saveInvoice(data){
        axios({
            url: '/clients/invoice/store',
            method: 'post',
            data: data
        }).then(function(response) {
             if (response.data.status == 'success') {
                store.commit('admin/setInvoice',response.data.data);
                store.commit('admin/setInvoiceValidate',true);
                 flash(response.data.message);
                 location.reload();

            }
        }).catch(function(error) {
            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });

    },
    updateInvoice(data){
        axios({
            url: '/clients/invoice/'+data.id+'/update',
            method: 'post',
            data: data
        }).then(function(response) {
             if (response.data.status == 'success') {
                store.commit('admin/setInvoice',response.data.data);
                store.commit('admin/setInvoiceValidate',true);
                 flash(response.data.message)
                 location.reload();

            }
        }).catch(function(error) {
            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });

    },
    saveFinalLES(data){
        axios({
            url: '/clients/final-les/store',
            method: 'post',
            data: data
        }).then(function(response) {
             if (response.data.status == 'success') {
                store.commit('admin/setFinalLES',response.data.data);
                store.commit('admin/setFinalLESValidate',true);
                 flash(response.data.message)


            }
        }).catch(function(error) {
            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });

    },
    updateFinalLES(data){
        axios({
            url: '/clients/final-les/'+data.id+'/update',
            method: 'post',
            data: data
        }).then(function(response) {
             if (response.data.status == 'success') {
                store.commit('admin/setFinalLES',response.data.data);
                 store.commit('admin/setFinalLESValidate',true);
                  flash(response.data.message)


            }
        }).catch(function(error) {
            if (error.response.status === 403) {
                flash(error.response.data.message,'danger')
            }
        });

    },
    addedFile(obj){
      console.log(obj);
    }
}
