import store from '../../store/store'
const admin = {

    uploadFiles1(obj){
      console.log("i am from ")
    },

    deleteFile(obj){
      if (!obj.id) {
        store.commit('admin/removeFile',obj);
        flash(response.data.message)
        return;
      }
      var url;
      switch (obj.doc) {
        case 'regDoc':
          url = '/clients/registration/document/'+obj.id+'/delete';
          break;
        case 'postLES':
          url = '/clients/posted-to-les/document/'+obj.id+'/delete';
          break;
        case 'lesApproved':
          url = '/clients/status/document/'+obj.id+'/delete';
          break;
        case 'courseComp':
          url = '/clients/course-completed/document/'+obj.id+'/delete';
          break;
        case 'invoice':
          url = '/clients/invoice/document/'+obj.id+'/delete';
          break;
        case 'finalLES':
          url = '/clients/final-to-les/document/'+obj.id+'/delete';
          break;
      default:

      }

      axios({
          url: url,
          method: 'get',
      }).then(function(response) {
          if (response.data.status == true) {
              store.commit('admin/removeFile',obj);
              flash(response.data.message)
          }
      }).catch(function(error) {
          if (error.response.status === 403) {
              flash(error.response.data.message,'danger')
          }
      });

    },
    addedFile(data){
      
    },
    updateLesApproved(data){
      console.log(JSON.stringify(data));
    }


 }


export default admin;
