<!--
  <ChangeDate format="dd M" date="2016-01-12" />

  dd : 02
  dth : 2nd
  M = Jan
  MM = January
  yyyy = 2019

 -->
<template>
    <span :style="mystyleStr">{{mydate}}</span>
</template>
<script>
export default {
        name: 'ChangeDate',
        props:{
        		date:String,
            format:String,
            mystyle:String
        },
        data(){
        	return {
        		months:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            fullmonths:['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

        	}
        },
        computed:{
          mystyleStr:{
            get(){
              try{
                  if(this.mystyle != undefined){
                    return this.mystyle;
                  }
              }catch(err){
                console.log("Error on mystyle :" + mystyle);
              }
            },set(val){
              return val;
            }
          },
   			mydate:{
   				get(){

   				    if  (this.date !== undefined && this.date !== null && this.date.length !=0 ) {

                        var splitDate = this.date.split("-");
                        var yy = splitDate[0];
                        var mm = parseInt(splitDate[1])-1;
                        var dd =splitDate[2];
                        var newDate;

                        var tempDate = new Date(this.date);
                        var tempDay = "0"+tempDate.getDate();

                        var day = tempDate.getDay();

                        if(this.format != undefined || this.format != null){

                          var mysplit = this.format.split(' ');

                          var mydate = this.format;

                          mydate = mydate.replace('dd',dd.split(" ")[0]);
                          mydate =mydate.replace('dth', this.convertTodth(tempDate.getDate()));
                          mydate = mydate.replace('mm',this.months[mm]);
                          mydate =  mydate.replace('MM',this.fullmonths[mm]);
                          mydate =  mydate.replace('MM',this.fullmonths[mm]);
                          mydate =  mydate.replace('yyyy',tempDate.getFullYear());



                        }else{
                          newDate = dd +" "+ this.months[mm] +" "+ yy;
                        }

                        return mydate;

                    }
   				}
   			}
   		},
  		methods:{
        convertTodth(val){
          var val1=val.toString().slice(-1);
          switch(val1){
            case "1":
            return val+'st';
            break;

            case "2":
            return val+"nd";
            break;

            case "3":
            return val+"rd";
            break;

            default:
            return val+"th";
          }
        }
  		}
  	}
 </script>
