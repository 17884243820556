/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

const GlobalEvent = require('./event').default;


window.Event = new GlobalEvent();

window.flash = function (message, level = 'success') {
    Event.fire('flash', { message, level });
};

// Vue.component('admin-notification', () => import('./components/notification/NotificationComponent.vue').default)
// Vue.component('app-admin', () => import('./components/admin/Admin.vue').default)
// Vue.component('flash', () => import('./components/FlashComponent.vue').default)
// Vue.component('clients', () => import('./components/client/ListComponent.vue').default)
// Vue.component('pagination', () => import('laravel-vue-pagination').default)
// Vue.component('app-admin', () => import('./components/admin/Admin.vue').default)
// Vue.component('file-upload', () => import('./components/FileUpload.vue').default)
// Vue.component('qc-show', () => import('./components/qc/Show.vue').default)
// Vue.component('notifications', () => import('./components/notification/ListComponent.vue').default)

Vue.component('admin-notification', require('./components/notification/NotificationComponent.vue').default)
Vue.component('flash', require('./components/FlashComponent.vue').default)
Vue.component('app-admin', require('./components/admin/Admin.vue').default)
Vue.component('clients',require('./components/client/ListComponent.vue').default)
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('app-admin',require('./components/admin/Admin.vue').default)
Vue.component('file-upload',require('./components/FileUpload.vue').default)
Vue.component('qc-show',require('./components/qc/Show.vue').default)
Vue.component('notifications',require('./components/notification/ListComponent.vue').default)


import store from './store/store'

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    store,
    el: '#app',
});