<template>
<div v-if="client.draft_status==0">
  <div v-if="client.step_id<2 || client.les_status.status!='rejected'">


    <h6><a :href="url">{{ client.name }}</a></h6>
    <p>
        <span class="client_id">Application ID</span> {{ client.id }}
    </p>
    <p v-if="client.award_title || client.awarding_body">
      {{ client.award_title }}
      <span v-if="client.awarding_body"><span v-if="client.award_title">,</span>
            {{ client.awarding_body.name }}
      </span>
    </p>
      <p>Start date: <app-cdate format="dth MM yyyy" :date="client.start_date"  /> End date:  <app-cdate format="dth MM yyyy" :date="client.finish_date"  /></p>
      <p v-if="client.amount">Amount(€): {{client.amount}}</p>
      <p v-if="client.corresponding_les_office">
          Corresponding LES: <span v-html="client.corresponding_les_office"></span>
      </p>
      <p>Email: {{client.les_mediator_email}}</p>
    <a :href="url" class="registration">
      {{ client.step ? client.step.title : '' }}
    </a>
    <ul class="list-inline">
        <li v-for="index in steps.length" :key="index">
            <a v-if="index ==2" href="#" :class="client.step_id >= index ? 'disabled':'disabled'"
              @click="preview(index)">
              <i class="fa fa-file-text-o" aria-hidden="true"></i></a>
            <a v-else href="#" :class="client.step_id >= index ? 'active':'disabled'"
               @click="preview(index)">
                <i class="fa fa-file-text-o" aria-hidden="true"></i></a>
        </li>
        <li>
          <span class="timeago">
            {{ lastUpdatedBy }} &nbsp;&nbsp;
            {{ lastUpdatedDate | moment("utc") | moment("from") }}
          </span>
        </li>

    </ul>
  </div>
</div>
</template>
<script>
 import ChangeDate from '../global/ChangeDate';
export default {
    name:'client',
    props: ['data','type','states'],
    components:{
       'app-cdate' : ChangeDate,
    },
    data() {
        return {
            client: this.data,
            role: this.type,
            steps: this.states,
        }
    },
    mounted() {
      this.client = this.data;
      this.role = this.type;
      this.steps = this.states;
      
    },
    computed: {
     url: function () {
       if (this.role == 'Super Admin') {
         return '/clients/'+this.client.id+'/edit';
       }
       if (this.role == 'Admin') {
         return '/clients/'+this.client.id+'/edit';
       }

       if (this.role == 'Finance' && this.client.step_id < 3) {
         return '#';
       }

       if (this.role == 'Finance' && this.client.step_id >= 3) {
         return '/clients/'+this.client.id+'/invoice';
       }

       if (this.role == 'QC/Management') {
         return '/clients/'+this.client.id+'/show';
       }

     },
     showDeleteIcon: function(){
       if (this.role == 'Super Admin' || this.role == 'Admin') {
         return true;
       }
       return false;
     },

     lastUpdatedBy: function(){
       var current = this.client.current_step;
       if (!current) {
           return this.client.user.name;
       }
       if(current == 'les_status'){
           return this.client.user.name;
       }
       if (this.client[current]) {
         var length = this.client[current].documents.length;
           if (length > 0) {
               return this.client[current].documents[length-1].user.name;
           }
       }
     },

     lastUpdatedDate: function(){
       var current = this.client.current_step;
       if (!current) {
           return this.client.updated_at;
       }
         if(current == 'les_status'){
             return this.client.updated_at;
         }
       if (this.client[current]) {
         var length = this.client[current].documents.length;
           if (length > 0) {
               return this.client[current].documents[length-1].created_at_8601;
           }
       }
     }
   },

    created() {

    },

    methods: {
      preview(step) {
        var client_id = this.client.id;
        this.$emit("preview",{client_id, step });
      },
    },

};
</script>
