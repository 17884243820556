<template>
  <div class="popup" v-show="showModal">
      <slot></slot>
      <button class="btn btn-transparent" @click.prevent="onConfirm()">Yes</button>
      <button class="btn btn-transparent" @click.prevent="onCancel()">No</button>
  </div>
</template>
<script>
export default {
    name:'confirmation-modal',
    props: ['show'],

    data() {
        return {
            showModal: this.show
        }
    },

    created() {

    },

    methods: {
      onConfirm() {
        this.$emit("confirm");
      },
      onCancel() {
        this.$emit("cancel");
      }
    },

};
</script>
