<template>
	<div class="fileDetail modal-custome" >
		<div class="modal fade" id="fileDetail" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true">
	        <div class="modal-dialog modal-dialog-centered modal-custome-inner" role="document">
	            <div class="modal-content">
	            	<div class="modal-inner-content">
	            	<div class="modal-header">
	            		

	            		<button type="button" data-dismiss="modal"  aria-label="Close" class="close">
							<img src="/img/Close-Cancel.png" alt="close"  class="close-icon">
						</button>
	            	</div>
	                <div class="modal-body text-center">
						 <span class="document-icon large">
                                <i class="fa fa-file-text-o" aria-hidden="true"></i>
                          </span>
	                	<!-- <h1>File Detail</h1> -->
	                	<ul>
	                		<!-- <li>ID : {{id}}</li> -->
	                		<li>{{name}}</li>
	                		<!-- <li>Doc : {{doc}}</li> -->
	                	</ul>
	                </div>
	              </div>
	          </div>
	        </div>
    	</div>
	</div>

</template>
<script>
export default {
	name:'fileDetail',
	props:{
		id:Number,
        name:String,
        doc:String
    },
	data(){
		return {
			mode:[],
			files:[],
             dropzoneOptions: {
                  url: 'https://httpbin.org/post',
                  thumbnailWidth: 150,
                  maxFilesize: 0.5,
                  headers: { "My-Awesome-Header": "header value" }
              }
		}
	},
	mounted() {
		try{   
        	

        }catch(err){
        	console.log("Error on mounted :" + err);
        }         
    },
}
</script>