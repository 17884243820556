<template>
	<li class="nav-item dropdown">
		<a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-label="Show notifications" @click="fetch">
			<div  aria-hidden="true" class="icon-bell">
				<span v-if="total > 0" class="badge badge-danger">{{ total }}</span>
			</div>
		</a>

		<ul class="app-notification dropdown-menu dropdown-menu-right">
			<li v-if="total > 0" class="app-notification__title">You have {{ total }} new notifications.</li>
			<div class="app-notification__content">

				<li v-for="(notification, index) in items">
					<a v-if="notification.type =='admin'" class="app-notification__item" :href="'/clients/'+notification.client_id+'/edit'">
						<span class="app-notification__icon"><span
					class="fa-stack fa-lg"><i class="fa fa-circle fa-stack-2x text-primary"></i><i
					class="fa fa-envelope fa-stack-1x fa-inverse"></i></span></span>
					<div>
						<p class="app-notification__message">{{ notification.message }}</p>
						<p class="app-notification__meta">{{ notification.created_at | moment("from") }}</p>
					</div>
				</a>

				<a v-if="notification.type =='account'" class="app-notification__item" :href="'/clients/'+notification.client_id+'/invoice'">
						<span class="app-notification__icon"><span
					class="fa-stack fa-lg"><i class="fa fa-circle fa-stack-2x text-primary"></i><i
					class="fa fa-envelope fa-stack-1x fa-inverse"></i></span></span>
					<div>
						<p class="app-notification__message">{{ notification.message }}</p>
						<p class="app-notification__meta">{{ notification.created_at | moment("from") }}</p>
					</div>
				</a>
			</li>

		</div>
		<li v-if="items.length > 0" class="app-notification__footer"><a href="/notifications">See all notifications.</a></li>
	</ul>

</li>
</template>

<script>
import VueMoment from 'vue-moment';
 Vue.use(VueMoment);

 export default {
 	name: 'admin-notification',
    components: {
        
    },
    data() {
        return { 
        	items: [],
        	total: '',
        	show: false
        };
    },
    created() {
        this.count();
    },
    methods: {

        count() {
           
            axios.get('/notifications/count')
            .then(response => {
               this.total = response.data;
                window.scrollTo(0, 0);
            });
        },

        fetch() {

            axios.get('/notifications')
            .then(response => {
            	this.show = true;
            	this.total = 0;
               this.items = response.data.data;
          
                window.scrollTo(0, 0);
            });
        },


    }
}

</script>