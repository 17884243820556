<template>
  <div>
    <template v-if="showModal">
     
      <app-previewmodal :id="data.id" :step="step" @previewModal="closePreview" :selected="selectedIndex"></app-previewmodal> 
      
    </template>
    <div class="file-uploder-wrapp">
      <ul>
      	<li>
      		<div class="media mb-5">
      			<span aria-hidden="true" class="icon-cloud-upload d-flex mr-3"></span>
      			<div class="media-body">
      				<h5 class="mt-0 mb-0">Invoice</h5>
            
                <a href="#" data-toggle="modal" @click="showDropZoneBox()"  data-target="#invoice-modal" data-backdrop="false" >Upload document</a>

                <ul class="list-inline">
                        <li class="list-inline-item"  v-for="(file,index) in invoiceFiles">
                            <a href="#" data-toggle="modal" @click="preview(index)" data-target="#fileDetail" data-backdrop="false"
                            data-id="file.id"  >
                            <span class="file-icon"><i class="fa fa-file-text-o" aria-hidden="true"></i></span></a>
                        </li>
                </ul>
             
               
      			</div>
      		</div>
      	</li>
      </ul>
    </div>


  <div class="modal-custome">
    <div id="invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" class="modal fade show" aria-modal="true">
      <div role="document" class="modal-dialog modal-dialog-centered modal-custome-inner">
         <div class="modal-content">
            <div class="modal-body">
               <div class="modal-inner-content">
                  <div class="modal-header"><button type="button" data-dismiss="modal" aria-label="Close" class="close"><img src="/img/Close-Cancel.png" alt="close" class="close-icon"></button></div>
                  <div class="file-drag-wrapp" v-if="showDropZone" >
                   
                    <vueDropzone 
                    id="fileupload" 
                    ref="myVueDropzone"
                    :options="dropzoneOptions"
                    @vdropzone-error="dropzoneErrMsg"
                     @vdropzone-complete="successUpload"
                     ></vueDropzone>
                   
                    <template v-if="invoiceFiles.length>0" class="fileList">
                        <Carousel navigationNextLabel="Next" navigationPrevLabel="Prev" :paginationEnabled="false" :perPage="perPage" :navigationEnabled="true" :scrollPerPage="true" :perPageCustom="[[320, 3], [768, 5]]" :autoplay="autoplay">
                            <slide v-for="(file,index,key) in invoiceFiles" :key="file.id">
                                <div class="slide-item">
                                <span class="close-icon-slide">
                                    <a href="#" @click="showDeletePopUp(file)" v-if="invoiceFiles.length>1"><img src="/img/Close-Cancel.png" alt="close"></a>
                                </span>
                                <i class="fa fa-file-text-o fa-5x"></i>
                                <div v-html="file.name"></div>

                                <span v-html="file.title"></span>
                                </div>
                            </slide>
                        </Carousel>
                    </template>

                    <div class="popup" v-if="showPop">
                      <span>Do you want to deleteFile  ?</span>
                      <button class="btn btn-transparent" @click="deleteFile(currentFile)" >Yes</button>
                      <button class="btn btn-transparent" @click="closePopUp">No</button>
                    </div>

                  </div>
                  <div class="modal-content-wrapp">
                     <div class="row">
                        
                        <div class="col-sm-12 col-md-6 d-flex justify-content-end  justify-content-md-end align-self-end align-self-md-end">
                           <div class="content-modal-save"><button @click="sendData($event)" type="submit" data-dismiss="modal" :class="['btn btn-transparent', invoiceClass]">Save </button></div>
                        </div>
                     </div>
                  </div> <!-- modal-content-wrapp end -->
               </div> <!-- modal-inner-content end -->
            </div>
         </div>
      </div>
   </div>
  </div>
</div>

</template>

<script>

import adminStore from '../store/modules/admin'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import PreviewModal from './client/PreviewModalComponent';
import { Carousel, Slide } from 'vue-carousel';

export default {
  name :'fileUpload',
  props:{
    data:Object,     
  },
  data(){
    return {
      mode:[],
      showDropZone:false,
      perPage:5,
      autoplay:true,
      showModal:false,
      client_id:null,
      step:4,
      showPop:false,
      selectedIndex:null,
      currentFile:'',
      dropzoneOptions: {
                    url: '/images/upload',
                    thumbnailWidth: 150,
                    parallelUploads:10,
                    acceptedFiles:'application/pdf,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,image/png,image/svg+xml,application/zip,application/x-bzip,application/x-bzip2,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/x-rar-compressed',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                   init: function() {
                        this.on("success", function(file, responseText) {
                            if(responseText.status == 'success'){
                                 flash(responseText.message)              
                                
                                this.client_id=responseText.id;
                                var obj = {
                                    id      : parseInt(responseText.image_name.slice(0,-5)),
                                    title   : responseText.image_name,
                                    url     : ''
                                }
                                window.store.commit('admin/addFileson_invoice', obj)
                            }
                        });
                    }
                },
    
    }
  },
  computed:{
    invoiceFiles:{
      get(){
        try{
          return this.$store.getters['admin/getInvoice'].files;
        }catch(err){
          console.log("Error on invoiceFiles : " + err);
        }
      },
      set(val){
        return val;
      }
    },
  
    invoiceClass:{
      get(){
        try{
          console.log(this.invoiceFiles);
          if(this.invoiceFiles.length>0){
            return '';
          }else{
            return 'disabled';
          }
        }catch(err){
          console.log("Error on invoiceFiles : " + err);
        }
      },
      set(val){
        this.$store.commit('admin/invoiceClass',val);
      }
    }

},
  created(){
    window.store=this.$store;
    this.$store.registerModule('admin', adminStore);
    this.fillMinimumData();
    this.fillInvoice();
    console.log(JSON.stringify(this.data));

  },
  mounted() {
    try{
      
    }catch(err){
          console.log("Error on mounted :" + err);
    }         
  },
  methods:{
     dropzoneErrMsg(file, message, xhr){
                 flash(message,'danger');
                 this.$refs.myVueDropzone.removeAllFiles();
            },
             successUpload(){
                this.$refs.myVueDropzone.removeAllFiles();
            },
    fillMinimumData(){
      try{
        var id= this.data.id;

        var clientInfo = {
          address:'',
          award_title:'',
          awarding_address:"",
          awarding_body:'',
          awarding_telephone:"",
          contact_no:"",
          dob:'',
          finish_date:"",
          id:this.data.id,
          name:this.data.name,
          start_date:"",
          step_id:0,
          total_days:1,
          validate:''
        }
        // Updating Data into vuex
        this.$store.commit('admin/setClientInfo',clientInfo);

      }catch(err){
        console.log("Error on methods/fillMinimumData : " + err);
      }
      
      
    },
    success(file,response){
      if(responseText.status == 'success'){
      flash('Successfully uploaded');
      var obj = {
        id      : parseInt(responseText.image_name.slice(0,-5)),
        title   : responseText.image_name,
        url     : ''
      }
      window.store.commit('admin/addFileson_invoice', obj)
      }
    },
    fillInvoice(){
      try{

       
        let invoiceFiles;
        let invoiceValidate;
        let id;


        if(this.data.invoice==null || this.data.invoice==undefined){
       
          invoiceFiles=[];
          invoiceValidate=false;
          id=0;
        }else{
      
          invoiceFiles=this.data.invoice.documents;
          invoiceValidate=this.data.invoice.validate;
          id= this.data.invoice.id;
        }     
              

        // Updating Data into vuex
        var invoice={
          id: id,
          files:invoiceFiles,
          validate:invoiceValidate
        }
        
        this.$store.commit('admin/setInvoice',invoice);



      }catch(err){
        console.log("Error on filllInvoice : " + err)
      }
    },
    sendData(event){
     try{
        var invoice = this.$store.getters['admin/getInvoice'];
      if(invoice.amount>0 && invoice.files.length>0){
        this.$store.commit('admin/setInvoiceValidate',true);

      }else{
        this.$store.commit('admin/setInvoiceValidate',false);
      }
      console.log(invoice);

        invoice.doc='invoice';
        invoice.client_id=this.data.id;
        this.$store.dispatch('admin/uploadFiles',invoice);


       this.$refs.myVueDropzone.removeAllFiles();
     }catch(err){
      console.log("Error on sendData : " + err);
     }
      
    },
     showDeletePopUp(file) {
        try {
            this.currentFile=file;
            this.showPop=true;
        } catch (err) {
            console.log("Error on showDeletePopUp :" + err);
        }
    },
    showDropZoneBox(){
      try{
          this.showDropZone=true;
           var findCarousel=setInterval(function(){
                              var carsousel = document.querySelectorAll('.VueCarousel-inner');
                              if(carsousel.length>0){
                                clearInterval(findCarousel);             
                                var i=0;
                                while(i<carsousel.length){
                                  carsousel[i].style.visibility='visible';
                                  i++;
                                }
                                
                              }
                            },1000); 
      }catch(err){
        console.log("Error on FileUpload/showDropZoneBox :" + err);
      }
    
      
    },
    deleteFile(){
      try {
             let obj = {
              'id': this.currentFile.id,
              'title': this.currentFile.title,
              'doc': 'invoice'
              };

              /* Normally files is saved numerically with the series of  1,2,3,4 and tempory start from greater than 99 */
              if(obj.id<80){
                     this.$store.dispatch('admin/deleteFile', obj); 
                  }else{
                      this.$store.commit('admin/removeFile',obj);
                      console.log("Remove");
                  }                        
              this.showPop=false;                   
      } catch (err) {
          console.log("Error on deleteFile :" + err);
      }
    },
    preview(index){
      try{
        this.selectedIndex=index;
        this.showModal=true;  
      }catch(err){
        console.log("Error on fileUpload/preview : " + err);
      }  
    },
    closePreview(){
      try{
        this.showModal=false
      }catch(err){
        console.log("Error on fileUpload/closePreview : " + err);
      }   
      
    },
    closePopUp(){
      try{
        this.showPop = false;
      }catch(err){
        console.log("Error on fileUpload/closePopUp : " + err);
      }   
    }    
  },
  components:{
    vueDropzone: vue2Dropzone,
     Carousel,
     Slide,
     'app-previewmodal':PreviewModal
      
  }
}
</script>