<template>
    <div class="modal-custome">
        <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-custome-inner" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="modal-inner-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearAllFiles">
                                    <img src="/img/Close-Cancel.png" alt="close" class="close-icon">
                                </button>
                            </div>
                            <div class="file-drag-wrapp">
                                <vueDropzone @vdropzone-error="dropzoneErrMsg" ref="myVueDropzone" :addRemoveLinks="addRemoveLinks" :duplicateCheck="duplicateCheck"
                                    id="dropzone" :options="dropzoneOptions"  
                                     @vdropzone-file-added="fileAdded" @vdropzone-complete="successUpload"
                                    ></vueDropzone>

                                <template v-if="myFiles.length>0" class="fileList">
                                    <Carousel navigationNextLabel="Next" navigationPrevLabel="Prev" :paginationEnabled="false" :perPage="perPage" :navigationEnabled="true" :scrollPerPage="true" :perPageCustom="[[320, 3], [768, 5]]">
                                        <slide v-for="(file,index,key) in myFiles" :key="file.id">
                                            <div class="slide-item">
                                            <span class="close-icon-slide">
                                                <a href="#" v-if="myFiles.length>1" :data-index="index" class="deleteFileBtn" @click="showDeletePopUp(file,$event)"><img src="/img/Close-Cancel.png" alt="close"></a>
                                            </span>
                                            <i class="fa fa-file-text-o fa-5x"></i>
                                            <div v-html="file.name"></div>

                                            <span v-html="file.title"></span>
                                            </div>
                                        </slide>
                                    </Carousel>
                                   
                                </template>
                                
                                <div class="popup" v-if="showPop">
                                    <span>Do you want to deleteFile  ?</span>
                                    <button class="btn btn-transparent" @click="deleteFile(currentFile)" >Yes</button>
                                    <button class="btn btn-transparent" @click="closePopUp">No</button>
                                </div>                                
     
                            </div>
                            <div class="modal-content-wrapp">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="content-details">
                                         <template v-if="currentDoc=='invoice'">
                                                <!-- Invoice -->
                                                <p>Invoice</p>
                                                <ul>
                                                    <li>
                                                         <input type="text" placeholder="Amount" class="form-control"
                                                        v-model="invoiceAmount">

                                                       
                                                    </li>
                                                   
                                                </ul>
                                            </template>
                                           
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 d-flex justify-content-end  justify-content-md-end align-self-end align-self-md-end">
                                        <div class="content-modal-save">

                                            <button type="submit" @click="sendAllData" :class="['btn btn-transparent',disablebtn]"
                                                data-dismiss="modal">Save </button>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import { Carousel, Slide } from 'vue-carousel';
    export default {
        name: 'dragUpload',
        props: {
            id: String,
        },
        data() {
            return {
                files: [],
                showPop:false,
                duplicateCheck: true,
                currentFile:'',
                fileID:0,
                showSlider:false,
                addRemoveLinks:true,
                currentIndex:null,
                initTotfile:0,
                dropzoneOptions: {
                    url: '/images/upload',
                    thumbnailWidth: 150,
                    parallelUploads: 10,
                    acceptedFiles:'application/pdf,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,image/png,image/svg+xml,application/zip,application/x-bzip,application/x-bzip2,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/x-rar-compressed',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                   init: function() {
                        this.on("success", function(file, responseText) {
                            if(responseText.status == 'success'){
                                 flash(responseText.message)
                                var funname="addFileson_"+window.store.getters['admin/getCurrentDoc'];
                                var obj = {
                                    id      : parseInt(responseText.image_name.slice(0,-5)),
                                    title   : responseText.image_name,
                                    url     : ''
                                }
                                window.store.commit('admin/'+funname, obj)
                            }
                        });
                    }
                },
                perPage:5,
            }
        },
        mounted(){
            this.initTotfile=this.myFiles.length;

            // Making Visible for Carousel after certain time
            setTimeout(function(){
                var body = document.getElementsByClassName('VueCarousel-inner');
                if(body.length>0){
                     body[0].style.visibility='visible';
                }
               
            },500)
        },
        created(){
            window.store=this.$store;
            this.$store.commit('admin/fillDocs');
        },
        computed: {

            

            postLES: {
                get() {
                    return this.$store.getters['admin/getPostLES'];
                },
                set(val) {
                    return val;
                }
            },
            courseComp: {
                get() {
                    return this.$store.getters['admin/getCourseComp'];
                },
                set(val) {
                    return val;
                }
            },

            invoice: {
                get() {
                    try {
                        return this.$store.getters['admin/getInvoice'];
                    }catch(err){
                        console.log("Error on invoice get :" + err)
                    }

                },
                set(val) {
                    try {
                        return val;
                    }catch(err){
                        console.log("Error on invoice set :" + err)
                    }
                }
            },
            finalLES: {
                get() {
                    try {
                        return this.$store.getters['admin/getFinalLES'];
                    }catch(err){
                         console.log("Error on finalLES/get : " + err)
                    }

                },
                set(val) {
                   
                    try {
                         return val;
                    }catch(err){
                         console.log("Error on finalLES/set : " + err)
                    }

                }
            },
            clientInfo: {
                get() {
                    try {
                        return this.$store.getters['admin/getClientInfo'];
                    } catch (err) {
                        console.log("Error on currentDoc :" + err);
                    }
                }
            },

            currentDoc: {
                get() {
                    try {
                        return this.$store.getters['admin/getCurrentDoc'];
                    } catch (err) {
                        console.log("Error on currentDoc :" + err);
                    }
                }
            },
            myFiles: {
                get() {
                    try {
                       return this.$store.getters['admin/getMyFiles']
                    } catch (err) {
                        console.log("Error on myFiles :" + err);
                    }
                }
            },
           
            disablebtn:{
                 get() {
                    try {
                       // console.log(JSON.stringify(this.$store.getters['admin/getMyFiles']));
                        
                        // POST LES
                        
                        if(this.currentDoc=='postLES'){
                            
                            if(this.myFiles.length<1){
                                return "disabled";
                            }else{
                                return "";
                            }
                        }else if(this.currentDoc=='courseComp'){

                            if( this.myFiles.length<1){
                                return "disabled";
                            }else{
                                return "";
                            }
                        }else if(this.currentDoc=='invoice'){
                            if(this.invoice.amount == null || this.invoice.amount == '' || this.myFiles.length<1){
                                return "disabled";
                            }else{
                                return "";
                            }
                        }else if(this.currentDoc=='finalLES'){
                            if(this.myFiles.length<1 || this.finalLES.posted_by =='' || this.finalLES.courier_name =='' || this.finalLES.tracking_id =='' ){
                                return "disabled";
                            }else{
                                return "";
                            }
                        }
                        
                    } catch (err) {
                        console.log("Error on disablebtn :" + err);
                    }
                }
            },
   
            postLESdocMode: {
                get() {
                    try {
                        return this.$store.getters['admin/getPostLESdocMode'];
                    } catch (err) {
                        console.log("Error on postLESdocMode:" + err);
                    }
                },
                set(val) {
                    try {
                        this.$store.commit('admin/setPostLESdocMode', val);
                    } catch (err) {
                        console.log("Error on postLESdocMode:" + err);
                    }
                }
            },
            courseCompStatus: {
                get() {
                    try {
                        return this.$store.getters['admin/getCourseCompStatus'];
                    } catch (err) {
                        console.log("Error on courseCompStatus :" + err);
                    }
                },
                set(val) {
                    try {
                        this.$store.commit('admin/setCourseCompStatus', val);
                    } catch (err) {
                        console.log("Error on courseCompStatus :" + err);
                    }
                }
            },
            invoiceAmount: {
                get() {
                    try {
                        return this.$store.getters['admin/getInvoiceAmount'];
                    } catch (err) {
                        console.log("Error on invoiceAmount :" + err);
                    }
                },
                set(val) {
                    try {
                        this.$store.commit('admin/setInvoiceAmount', val);
                    } catch (err) {
                        console.log("Error on invoiceAmount :" + err);
                    }
                }
            },
            postLESPostBy: {
                get() {
                    return this.$store.getters['admin/getPostLESPostBy'];
                },
                set(val) {
                    console.log(val);
                    this.$store.commit('admin/setPostLESPostBy', val);
                }
            },
            postLESCourier: {
                get() {
                    try {
                        return this.$store.getters['admin/getPostLESCourier'];
                    } catch (err) {
                        console.log("Error on postLESCourier :" + err);
                    }
                },
                set(val) {
                    try {
                        this.$store.commit('admin/setPostLESCourier', val);
                    } catch (err) {
                        console.log("Error on postLESCourier :" + err);
                    }
                }
            },
            postLESTrackID: {
                get() {
                    try {
                        return this.$store.getters['admin/getPostLESTrackID'];
                    } catch (err) {
                        console.log("Error on postLESTrackID :" + err);
                    }
                },
                set(val) {
                    try {
                        this.$store.commit('admin/setPostLESTrackID', val);
                    } catch (err) {
                        console.log("Error on postLESTrackID :" + err);
                    }
                }
            },
            
           
        },
        methods: {
            clearAllFiles() {
                try {
                    // Removing files on files []
                    console.log(this.currentDoc);
                   // this.$store.commit('admin/removefromFiles',this.currentDoc)
                    this.$refs.myVueDropzone.removeAllFiles();
                    this.closePopUp();
                } catch (err) {
                    console.log("Error on  clearAllFiles :" + err);
                }
            },
            dropzoneErrMsg(file, message, xhr){
               flash(message,'danger');
               this.$refs.myVueDropzone.removeAllFiles();
            },
            fileAdded(file){
              this.$store.dispatch('admin/addedFile',file);
            },
            successUpload(){
                this.$refs.myVueDropzone.removeAllFiles();
            },
            sendAllData() {
                try {
                    this.$refs.myVueDropzone.removeAllFiles();
                    this.closePopUp();
                    var obj ={}
                    var doc="get"+this.currentDoc.charAt(0).toUpperCase() + this.currentDoc.slice(1);
                    obj=this.$store.getters['admin/'+doc];
                    obj.client_id = this.$store.getters['admin/getClientID'];
                    obj.doc=this.currentDoc;
                    this.$store.dispatch('admin/uploadFiles',obj);


                } catch (err) {
                    console.log("Error on sendAllData :" + err);
                }
            },
            showDeletePopUp(file,event) {
                try {                   
                   
                    this.currentFile=file;
                    this.showPop=true;
                    this.currentIndex=parseInt(event.currentTarget.dataset.index);
                    var index = parseInt(event.currentTarget.dataset.index);
                    this.$store.commit('admin/setCurrentIndex',index);                 

                } catch (err) {
                    console.log("Error on showDeletePopUp :" + err);
                }
            },
            deleteFile(){
                try {
                        let obj = {
                            'id': this.currentFile.id,
                            'title': this.currentFile.title,
                            'doc': this.currentDoc,
                            'index': this.currentIndex,
                            'initTotfile': this.initTotfile
                            };
                            console.log(obj);
                            if(obj.id<80){
                               this.$store.dispatch('admin/deleteFile', obj); 
                            }else{
                                this.$store.commit('admin/removeFile',obj);
                                console.log("Remove");
                            }
                            
                        this.showPop=false;

                    
                } catch (err) {
                    console.log("Error on deleteFile :" + err);
                }
            },
            closePopUp(){
                try {
                    this.showPop=false;
                    this.$store.commit('admin/showFileUpload',false);
                    
                } catch (err) {
                    console.log("Error on closePopUp :" + err);
                }
            },
            destory(){
                  $('.fileList').slick('unslick');
            },
            reinit(){
                console.log("Re init");
                $('.fileList').slick({
                    infinite: false,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    arrows: true
                });
            }
        },
        components: {
            vueDropzone: vue2Dropzone,
            Carousel, 
            Slide
            
           
        }
    }
</script>
<style>
    .dropzone .dz-preview .dz-remove{
        opacity: 0 !important;
    }
    textarea.comment-box {
        width: 100%;
        margin-top: 15px;
        padding: 5px;
    }

</style>