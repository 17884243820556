<template>
	<div>
		<template v-if="showFileSlider">
			
               <app-previewmodal :id="client_id" :step="parseInt(step)" @previewModal="closePreview" :selected="index"></app-previewmodal>
            </template>
		 <a @click="fetch()" class="fileicon" href="#" :data-index="index">
		 	<span class="file-icon">
		 	<i class="fa fa-file-text-o" aria-hidden="true"></i></span>
                                        </a>	
	</div>
</template>

<script>
import PreviewModal from '../client/PreviewModalComponent';

export default {
 	name : 'appshow',
 	data() {
 		return {
 			showFileSlider:false
 		}
 	},
 	props:{
 		client_id:Number,
 		step:String,
 		index:Number,
 		

 	},
 	created(){
 		//this.docStep=parseInt(this.step);
 		//this.fetch();
 	},
 	methods: {

        fetch() {
        	
            
                this.showFileSlider=true;
              
          

        },
        closePreview(){
    	this.showFileSlider=false
    }
    },
    components:{
    	'app-previewmodal':PreviewModal
    },


 }
 </script>
