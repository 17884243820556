<template>
	<div class="container w-75" v-if="show">
		<a v-for="(notification, index) in items"  :href="url(notification)" class="notification-wrapp mb-3">
			<div class="notification-header">
				<div class="media">
					<span class="date d-flex mr-3">{{ notification.created_at | moment("D") }}</span>
					<div class="media-body mt-2">
						<h5 class="mt-0 mb-0">{{ notification.created_at | moment("ddd") }}</h5>
						{{ notification.created_at | moment('MMM YYYY') }}
					</div>
				</div>
			</div>
			<div class="notification-body">
				<div class="media">
					<img class="d-flex mr-3" src="/img/Notification.png">
					<div class="media-body">
						<h5 class="mt-0">{{ notification.title }}</h5>
						{{ notification.message }}

					</div>
				</div>
			</div>
		</a>

		<p v-if="items.length == 0">No Any New Notifications.</p>
		<div class="pagination-wrapp">
            <pagination :data="dataSet" @pagination-change-page="fetch"></pagination>
        </div>

	</div>
</template>
<script>

 import VueMoment from 'vue-moment';
 Vue.use(VueMoment);

 export default {
    components: {
    },
    data() {
        return {
            dataSet: {},
            items:[],
            page: 1,
            q:'',
            show: false
        };
    },
    created() {
        this.fetch();
    },
    beforeDestroy () {

    },
    computed:{
    },
    methods: {

        fetch( page = 1) {
            this.page = page;
            axios.get('/notifications?q='+this.q+'&page=' + this.page)
            .then(response => {
                this.show = true;
                this.dataSet = response.data;
                this.items = response.data.data;
              window.scrollTo(0, 0);
            });
        },
				url(notification){
					if (notification.type =='admin') {
						return '/clients/'+notification.client_id+'/edit';
					}
					if (notification.type =='account') {
						return '/clients/'+notification.client_id+'/invoice';
					}
				}

    }
}

</script>
