<template>
    <div class="bs-component" id="flash-section">
        <div class="alert alert-flash alert-success alert-dismissible animated bounceInRight"
         :class="'alert-'+level"
         role="alert"
         v-show="show">
        <button class="close" type="button" data-dismiss="alert">×</button>
        <span v-text="body"></span>
        </div>
    </div>
</template>

<script>

    export default {
        name:'flash',
        props: ['message'],

        data() {
            return {
                body: this.message,
                level: 'success',
                show: false
            }
        },

        created() {
           Event.listen('flash', data => this.flash(data));
        },

        methods: {
            flash(data) {
                if (data) {
                    this.body = data.message;
                    this.level = data.level;
                }

                this.show = true;

                this.hide();
            },

            hide() {
                setTimeout(() => {
                    this.show = false;
                }, 5000);
            }
        },

    };
</script>

<style>
    .alert-flash {
        position: fixed;
        right: 15px;
        bottom: 0px;
        z-index: 9999;
    }

</style>