<template>
    <div>
        <div class="modal-custome">
            <div id="previewModal" class="modal" aria-modal="true">
                <a href="#" @click="showPrev" v-if="currentIndex>0" class="prevBtn" ><i class="fa fa-arrow-left"></i></a>
            <a href="#" @click="showNextFile" v-if="showNext" class="nextBtn" ><i class="fa fa-arrow-right"></i></a>
                <div role="document" class="modal-dialog modal-dialog-centered modal-custome-inner">
                    <div class="modal-content">
                        <div class="modal-header">
                        <button type="button" @click="close"  data-dismiss="modal"  aria-label="Close" class="close">
                            <img src="/img/Close-Cancel.png" alt="close"  class="close-icon">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal-inner-content">
                            <div class="file">
                                <template v-if="currentFileType=='jpg' || currentFileType=='peg' || currentFileType=='gif' || currentFileType=='png' || currentFileType=='svg'">
                                    <img :src="url" /><br />
                                    <span v-html="filename"></span>
                                </template>
                                <template v-else-if="currentFileType=='pdf'">
                                    <!-- +"?#zoom=85&scrollbar=0&toolbar=0&navpanes=0" -->
                                    <object  width="100%" :height="pdfHeight" type="application/pdf" :data="url">
                                        <p>Insert your error message here, if the PDF cannot be displayed.</p>
                                    </object>
                                </template>
                                <template v-else>
                                    <i class="fa fa-file-text-o fa-5x"></i> <br />
                                    <span v-html="filename"></span><br /><br />
                                </template>
                                <br /><br />
                                <span class="uploaded-details" :title="created_at">Uploaded By: {{ uploaded_by }}
                                , {{ created_at_8601 | moment("from") }}
                              </span>
                                <a class="btn btn-transparent" :href="url" download="file">Download</a>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
 export default {
    name : 'previewModalComponent',
    data() {
        return {
            currentIndex:0,
            currentFileType:'fd',
            currentFile:null,
            documents:[],
            filename:null,
            url:null,
            total:null,
            lastIndex:null,
            pdfURL:null,
            pdfWidth:null,
            pdfHeight:null,
            showModal:false,
            uploaded_by:null,
            created_at_8601:null,
            created_at: null,

        }
    },
    created(){
        this.fetch();
    },
    computed:{
        showNext:{
            get(){
                if(this.currentIndex<this.lastIndex){
                return true;
                }else{
                    return false;
                }
            },
            set(val){
                return val;
            }

        },

    },
    props:{
        id:Number,
        step:Number,
        selected:Number
    },
    methods: {
        fetch() {
            axios.get('/clients/'+this.id+'/step/' +this.step)
            .then(response => {
               // console.log("Response Data : ");
               // console.log(response.data);
                this.documents=response.data;
                if(this.selected!=undefined){
                    console.log("I got selected");
                    this.currentIndex=this.selected;
                }else{
                    console.log("Not");
                }
                this.manageData();
                this.lastIndex=this.documents.length-1;
                this.pdfWidth=window.innerWidth-200;
                this.pdfHeight=window.innerHeight-200;
            });
        },
        close(){
            this.$emit('previewModal', false);
        },
        showNextFile(){
            this.currentIndex++;
            this.manageData();
        },
        showPrev(){
            this.currentIndex--;
            this.manageData();
        },
        manageData(){
    		this.currentFileType=this.documents[this.currentIndex].title.slice(-3).toLowerCase();
        	this.url=this.documents[this.currentIndex].url;
          this.filename=this.documents[this.currentIndex].title;
          this.uploaded_by=this.documents[this.currentIndex].user.name;
          this.created_at_8601=this.documents[this.currentIndex].created_at_8601;
    		   this.created_at=this.documents[this.currentIndex].created_at;
        }

    },
 }
</script>
<style lang="scss" scoped>
.modal-custome-inner{max-width:1200px; }
.modal{display:block}
.close{position:fixed; right: 50px; top:50px;}
.modal-header{border:none}
.fa-5x{font-size:250px; color:#FE5E08; margin-bottom:10px;}
.file{text-align:center}
img{border-radius:10px;}
.nextBtn,.prevBtn{
      position:absolute;
      width:55px;
     height:55px;
     border:solid 1px #ccc;
     border-radius:55px;
     text-align:center;
     line-height:50px;
     color:#ccc;
     top:50%;
}
.btn{display:inline-block}
.nextBtn{


     right: 25px;

}
.prevBtn{


     left: 25px;
}
span.uploaded-details{
  display:block;
  margin-bottom: 15px;
  font-weight: 600;
}
</style>
Collapse
