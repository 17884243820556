const mutations = {
    setPostLESPost(state,data){
        try{
            state.postLES=data;           
        }catch(err){
            console.log("Error on setPostLESPost :"+err);
            return false;
        }
    },
    setPostLES(state,data){
        try{
            state.postLES=data;           
        }catch(err){
            console.log("Error on setPostLESPost :"+err);
            return false;
        }
    },
    setClientInfoValidate(state,data){
        try{
            state.clientInfo.validate=data;           
        }catch(err){
            console.log("Error on setClientInfoValidate :"+err);
            return false;
        }
    },
   
    setPostedToLESValidate(state,data){
        try{
             Vue.set(state.postLES,'validate',data);
        }catch(err){
            return false;
        }
    },
    setLessApprovedValidate(state,data){       
        try{

            state.lesApproved.validate=data;           
        }catch(err){
            console.log("Error on setLessApprovedValidate :"+err);
            return false;
        }
    },
    setCourseCompValidate(state,data){       
        try{
            state.courseComp.validate=data;           
        }catch(err){
            console.log("Error on setCourseCompValidate :"+err);
            return false;
        }
    },
    setInvoiceValidate(state,data){       
        try{
            state.invoice.validate=data;           
        }catch(err){
            console.log("Error on setInvoiceValidate :"+err);
            return false;
        }
    },
    removefromFiles(state,data){
        try{
            state[data].files=[];
           
        }catch(err){
            console.log("Error on setPostLESPost :"+err);
            return false;
        }
    },
    setCommentOnLesApproved(state,data){
        try{
            state.lesApproved.comment=data;
           
        }catch(err){
            console.log("Error on setCommentonLesApproved :"+err);
            return false;
        }
        
    },
    setLesApproved(state,data){
        try{
            state.lesApproved=data;
        }catch(err){
            console.log("Error on setLesApproved :"+err);
            return false;
        }
    },
    setCourseCompleted(state,data){
        try{
            state.courseComp=data;
        }catch(err){
            console.log("Error on setCourseCompleted :"+err);
            return false;
        }
    },
    setInvoice(state,data){
        try{
            state.invoice=data;
        }catch(err){
            console.log("Error on setInvoice :"+err);
            return false;
        }
    },
    step(state,data){
    try{
            state.step=data;
        }catch(err){
            console.log("Error on step :"+err);
            return false;
        }
   },
    setClientInfo(state, data) {
        try{
            state.clientInfo=data;
        }catch(err){
            console.log("Error on setClientInfo :"+err);
            return false;
        }
    },
    setTempClientInfo(state, data) {
        try{
            state.clientInfo=data;
        }catch(err){
            console.log("Error on setTempClientInfo :"+err);
            return false;
        }
    },
    
    name(state,data){
        try{
            state.clientInfo.name=data;
        }catch(err){
            console.log("Error on name :"+err);
            return false;
        }
    },
    email(state,data){
        try{
            state.clientInfo.email=data;
        }catch(err){
            console.log("Error on email :"+err);
            return false;
        }
    },
    contact_no(state,data){
        try{
            state.clientInfo.contact_no=data;
        }catch(err){
            console.log("Error on contact_no :"+err);
            return false;
        }
    },
    start_date(state,data){
        try{
            state.clientInfo.start_date=data;
        }catch(err){
            console.log("Error on start_date :"+err);
            return false;
        }
    },
    finish_date(state,data){
        try{
            state.clientInfo.finish_date=data;
        }catch(err){
            console.log("Error on finish_date :"+err);
            return false;
        }
    },
    award_title(state,data){
        try{
            state.clientInfo.award_title=data;
        }catch(err){
            console.log("Error on award_title :"+err);
            return false;
        }
    },
    amount(state,data){
        try{
            state.clientInfo.amount=data;
        }catch(err){
            console.log("Error on amount :"+err);
            return false;
        }
    },
    general_comments(state,data){
        try{
            state.clientInfo.general_comments=data;
        }catch(err){
            console.log("Error on general_comments :"+err);
            return false;
        }
    },
    opportunity_link(state,data){
        try{
            state.clientInfo.opportunity_link=data;
        }catch(err){
            console.log("Error on opportunity_link :"+err);
            return false;
        }
    },
    corresponding_les_office(state,data){
        try{
            state.clientInfo.corresponding_les_office=data;
        }catch(err){
            console.log("Error on corresponding_les_office :"+err);
            return false;
        }
    },
    les_mediator_email(state,data){
        try{
            state.clientInfo.les_mediator_email=data;
        }catch(err){
            console.log("Error on les_mediator_email :"+err);
            return false;
        }
    },
    setClientID(state, data) {
        try{
           state.clientInfo.id=data;
        }catch(err){
            console.log("Error on setClientID :"+err);
            return false;
        }
    },
    setPostLESPostBy(state,data){
    	try{
    		state.postLES.posted_by=data;
    	}catch(err){
    		console.log("Error on getPostBy :"+err);
    		return false;
    	}
    },
    
    setPostLESCourier(state,data){
    	try{
    		state.postLES.courier_name=data;
    	}catch(err){
    		console.log("Error on getCourier :"+err);
    		return false;
    	}
    },
    setPostLESTrackID(state,data){
    	try{
    		state.postLES.tracking_id=data;
    	}catch(err){
    		console.log("Error on getTrackID :"+err);
    		return false;
    	}
    },
    setLesApprovedStatus(state,data){
    	try{
    		state.lesApproved.status=data;
    	}catch(err){
            console.log("Error on setLesApprovedStatus :"+err);
    		return false;
    	}
    },
    setCourseCompStatus(state,data){
    	try{
    		state.courseComp.status=data;
    	}catch(err){
    		console.log("Error on setCourseCompStatus :"+err);
    		return false;
    	}    	
    },
    addFileson_postLES(state,data){
       try{
            data.id=Math.floor(Math.random() * 999);
    		state.postLES.files.push(data);
    	}catch(err){
    		console.log("Error on  addFileson_postLES:"+err);
    		return false;
    	}
    	
    },
    addFileson_courseComp(state,data){
    	try{
             data.id=Math.floor(Math.random() * 999);
    		state.courseComp.files.push(data);
    	}catch(err){
    		console.log("Error on addFileson_courseComp :"+err);
    		return false;
    	}
    	
    },
    addFileson_invoice(state,data){
    	try{
             data.id=Math.floor(Math.random() * 999);
    		state.invoice.files.push(data);
    	}catch(err){
    		console.log("Error on  addFileson_invoice :"+err);
    		return false;
    	}
    	
    },
    showFileUpload(state,data){
        try{
            state.showFileUpload=data;
        }catch(err){
            console.log("Error on  addFileson_invoice :"+err);
            return false;
        }
    },
    addFileson_lesApproved(state,data){
         console.log("addFileson_lesApproved :" + JSON.stringify(data));
    	try{
            data.id=Math.floor(Math.random() * 999);
            state.lesApproved.files.push(data);
    	}catch(err){
    		console.log("Error on  addFileson_lesApproved:"+err);
    		return false;
    	}
    	
    },
    setFileDetail(state,data){
    	console.log(JSON.stringify(data));
    	try{
    		let obj = state[data.doc].files.filter(todo=>todo.id==data.id);
    		if(obj.length==1){
    			obj[0].doc=data.doc;
    			state.fileDetail=obj[0];
    		}else{
    			console.log("Either not found or duplicate id found on Reg Doc Files");
    			return false;
    		}
    		//console.log(JSON.stringify(state.detailFile));
    	}catch(err){
    		console.log("Error on setDetailFile :"  +err);
    		return false;
    	}
    	
    },
    setCurrentDoc(state,data){
    	try{
    		return state.currentDoc=data;
    	}catch(err){
    		console.log("Error on getFileDetail :" + err);
    		return false;
    	}

    },
    removeFile(state,data){
    	try{
            let obj = state[data.doc].files.find(todo=>todo.title==data.title);
            let index = state[data.doc].files.indexOf(obj);
            state[data.doc].files.splice(index,1);
        }catch(err){
    		console.log("Error on removeFile :" + err);
    	}

    },
    setShowFileUpload(state,data){
        try{
           state.showFileUpload=data;
        }catch(err){
            console.log("Error on setShowFileUpload :" + err);
        }
    },
    fillDocs(state,data){
         try{
          // state[state.currentDoc].files=state[state.currentDoc].files;
          return true;
           console.log(state[state.currentDoc].files);
        }catch(err){
            console.log("Error on fillDocs :" + err);
        }
    },
    setCurrentIndex(state,data){
        try{
            state.currentIndex=data;
        }catch(err){
            console.log("Error on setCurrentIndex :" + err);
            return false;
        }        
    },
    setEmailSend(state,data){
        try{
            state.emailSend=data;  

        }catch(err){
            console.log("Error on setEmailSend :" + err);
            return false;
        }   
    },
    setEmailSendProgress(state,data){
        try{
           
            state.emailSend.progress=data;
            return true;
        }catch(err){
            console.log("Error on setEmailSendProgress :" + err);
            return false;
        } 
    },
    setDratStatus(state,data){
         try{
            
            return state.clientInfo.draft_status = (data == true ? 1 : 0 );
            
        }catch(err){
            console.log("Error on setDratStatus :" + err);
            return false;
        }
    },
    setCompletionFlag(state,data){
        
        try{
            return state.clientInfo.completion_flag = (data == true ? 1 : 0 );

        }catch(err){
            console.log("Error on setCompletionFlag :" + err);
            return false;
        }
    }

    
}

export default (mutations);
